<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/Alacris/Mobile/Alacris_header.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Alacris/Mobile/Alacris_header-iphone.png"
          media="(max-width: 414px)"
        />
        <source
          srcset="/img/images/portfolio/Alacris/Alacris_header.png"
          media="(max-width: 1699px)"
        />
        <source
          srcset="/img/images/portfolio/Alacris/Alacris_header-full-hd.png"
          media="(min-width: 1700px)"
        />

        <img
          class="main-img_portfolio main-img_alacris"
          src="/img/images/portfolio/Alacris/Alacris_header-full-hd.png"
          alt
        />
      </picture>
      <section class="wrapper current-portfolio current-portfolio_alacris">
        <picture>
          <source srcset="/img/images/portfolio/Alacris/Mobile/bg_1.png" media="(max-width: 500px)" />
          <img class="bg-alacris_1" src="/img/images/portfolio/Alacris/bg_1.png" alt />
        </picture>
        <h1 class="current-portfolio_title">{{$t("alacris.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("alacris.hash-tag1")}}</li>
          <li>{{$t("alacris.hash-tag2")}}</li>
          <li>{{$t("alacris.hash-tag3")}}</li>
          <li>{{$t("alacris.hash-tag4")}}</li>
          <li>{{$t("alacris.hash-tag5")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("alacris.name")}}</em>
          {{$t("alacris.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("alacris.description")}}</p>
      </section>

      <div class="wrapper wrapper-alacris">
        <div class="portfolio-description challenge-portfolio">
          <section>
            <h2>{{$t("alacris.challenge")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              <a target="_blank" href="https://alacris.io/">{{$t("alacris.name")}}</a>
              {{$t("alacris.text1")}}
            </p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Alacris/Mobile/Challenge.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Alacris/Mobile/Group_6-iphone.png"
              media="(max-width: 414px)"
            />
            <img
              class="alacris-img_challenge"
              src="/img/images/portfolio/Alacris/Challenge.png"
              alt
            />
          </picture>
        </div>

        <div class="portfolio-description portfolio-audience">
          <section>
            <h2>{{$t("alacris.target-audience")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("alacris.text2")}}</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Alacris/Mobile/target_audience.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Alacris/Mobile/Bitmap_Copy-iphone.png"
              media="(max-width: 414px)"
            />
            <img
              class="target-audience"
              src="/img/images/portfolio/Alacris/target_audience.png"
              alt
            />
          </picture>
        </div>

        <div class="portfolio-description portfolio-things_we-did">
          <section>
            <h2>{{$t("alacris.things")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("alacris.text3")}}</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Alacris/Mobile/Things_we_did.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Alacris/Mobile/alacris_wallet_ui-iphone.png"
              media="(max-width: 414px)"
            />
            <img class="img-things_did" src="/img/images/portfolio/Alacris/Things_we_did.png" alt />
          </picture>
        </div>
      </div>
    </div>

    <section class="more-projects alacris-more-projects">
      <h2 class="main-title">More projects</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "alacris",
  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Ember.png",
          image_hover: "/img/images/portfolio/main/Ember_clear.png",
          title: "Embr Wave",
          url: "/portfolio/ember",
          tags: [
            {
              name: "#Design Support"
            },
            {
              name: "#Mobile App"
            },
            {
              name: "#IoT"
            },
            {
              name: "#Wearables"
            },
            {
              name: "#User Research"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Stressguide.png",
          image_hover: "/img/images/portfolio/main/Stressguide_clear.png",
          title: " Stress Guide",
          url: "/portfolio/stress-guide",
          tags: [
            {
              name: "#Mobile App"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#User Research"
            },
            {
              name: "#Healthcare"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Earth_rating.png",
          image_hover: "/img/images/portfolio/main/Earth_rating_clear.png",
          title: "Earth Rating",
          url: "/portfolio/rating",
          tags: [
            {
              name: "#Web App Prototyping"
            },
            {
              name: "#Presentation"
            },
            {
              name: "#Ecommerce"
            },
            {
              name: "#Environment"
            }
          ]
        }
      ]
    };
  }
};
</script>