<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/Infra/Mobile/Infra_header_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Infra/Mobile/Infra_header_mobile-iphone.png"
          media="(max-width: 414px)"
        />
        <source
          srcset="/img/images/portfolio/Infra/Infra_header_img.png"
          media="(max-width: 1699px)"
        />
        <source
          srcset="/img/images/portfolio/Infra/Infra_header_img-full-hs.png"
          media="(min-width: 1700px)"
        />

        <img
          class="main-img_portfolio infra-main_img"
          src="/img/images/portfolio/Infra/Infra_header_img-full-hs.png"
          alt
        />
      </picture>

      <picture>
        <source
          srcset="/img/images/portfolio/Infra/Mobile/Infra_bg_image_1_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Infra/Mobile/Infra_bg_image_1_mobile-iphone.png"
          media="(max-width: 414px)"
        />

        <img
          class="main-img_portfolio infra-main_bg"
          src="/img/images/portfolio/Infra/Infra_bg_image_1.png"
          alt
        />
      </picture>

      <section class="wrapper current-portfolio current-infra">
        <h1 class="current-portfolio_title">{{$t("infra.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("infra.hash-tag1")}}</li>
          <li>{{$t("infra.hash-tag2")}}</li>
          <li>{{$t("infra.hash-tag3")}}</li>
          <li>{{$t("infra.hash-tag4")}}</li>
          <li>{{$t("infra.hash-tag5")}}</li>
          <li>{{$t("infra.hash-tag6")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("infra.name")}}</em>
          {{$t("infra.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("infra.description")}}</p>
      </section>

      <div class="portfolio-description infra-description wrapper">
        <section>
          <h2>{{$t("infra.description")}}</h2>
          <div class="horizontal-line"></div>
          <p>
            <a target="_blank" href="https://www.infrascan.net/">INFRA</a>
            {{$t("infra.text1")}}
          </p>
          <br />
          <p>{{$t("infra.text2")}}</p>
          <br />
          <p>{{$t("infra.text3")}}</p>
        </section>

        <picture>
          <source
            srcset="/img/images/portfolio/Infra/Mobile/target_user_img_mobile.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Infra/Mobile/target_user_img_mobile-iphone.png"
            media="(max-width: 414px)"
          />

          <img class="infra-target_user" src="/img/images/portfolio/Infra/target_user_img.png" alt />
        </picture>
      </div>

      <div class="wrapper-infra">
        <div class="container-infra">
          <div class="portfolio-description infra-restruction_desc wrapper">
            <section>
              <h2>{{$t("infra.restructuring")}}</h2>
              <div class="horizontal-line"></div>
              <p>{{$t("infra.text4")}}</p>
              <br />
              <p>{{$t("infra.text5")}}</p>
            </section>
            <picture>
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_1_mobile.png"
                media="(max-width: 320px)"
              />
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_1_mobile-iphone.png"
                media="(max-width: 414px)"
              />

              <img
                class="infra-restructing_1"
                src="/img/images/portfolio/Infra/RESTRUCTURING_1.png"
                alt
              />
            </picture>
          </div>
          <div class="wrap-restruction wrapper">
            <picture>
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_2_mobile.png"
                media="(max-width: 320px)"
              />
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_2_mobile-iphone.png"
                media="(max-width: 414px)"
              />

              <img
                class="infra-restruction_2"
                src="/img/images/portfolio/Infra/RESTRUCTURING_2.png"
                alt
              />
            </picture>
            <picture>
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_3_mobile.png"
                media="(max-width: 320px)"
              />
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_3_mobile-iphone.png"
                media="(max-width: 414px)"
              />

              <img
                class="infra-restruction_3"
                src="/img/images/portfolio/Infra/RESTRUCTURING_3.png"
                alt
              />
            </picture>
          </div>
          <div class="wrap-restruction wrapper">
            <picture>
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_4_mobile.png"
                media="(max-width: 320px)"
              />
              <source
                srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_4_mobile-iphone.png"
                media="(max-width: 414px)"
              />

              <img
                class="infra-restruction_4"
                src="/img/images/portfolio/Infra/RESTRUCTURING_4.png"
                alt
              />
            </picture>
          </div>
        </div>
        <div class="wrapper wrapper-infra_feel">
          <div class="portfolio-description infra-feel_desc">
            <section>
              <h2>{{$t("infra.look-feel")}}</h2>
              <div class="horizontal-line"></div>
              <p>{{$t("infra.text6")}}</p>
              <br />
              <p>{{$t("infra.text7")}}</p>
            </section>
            <picture>
              <source
                srcset="/img/images/portfolio/Infra/Mobile/Look_and_feel_img_mobile.png"
                media="(max-width: 320px)"
              />
              <source
                srcset="/img/images/portfolio/Infra/Mobile/Look_and_feel_img_mobile-iphone.png"
                media="(max-width: 414px)"
              />

              <img
                class="infra-feel_img"
                src="/img/images/portfolio/Infra/Look_and_feel_img.png"
                alt
              />
            </picture>
          </div>
        </div>
      </div>

      <picture>
        <source
          srcset="/img/images/portfolio/Infra/Mobile/line_divider_mobile.png"
          media="(max-width: 500px)"
        />
        <img class="infra-divider" src="/img/images/portfolio/Infra/line_divider.png" alt />
      </picture>

      <div class="wrapper">
        <div class="portfolio-description">
          <section>
            <h2>{{$t("infra.result")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("infra.text8")}}</p>
          </section>
        </div>
        <picture>
          <source
            srcset="/img/images/portfolio/Infra/Mobile/Result_img_mobile.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Infra/Mobile/Result_img_mobile-iphone.png"
            media="(max-width: 414px)"
          />

          <img class="infra-result_img" src="/img/images/portfolio/Infra/Result_img .png" alt />
        </picture>
      </div>
    </div>

    <img class="infra-footer-bg" src="/img/images/portfolio/Infra/bottom_dark_bg.png" alt />
    <section class="more-projects infra-more_projects">
      <h2 class="main-title">More projects</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "infra",
  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Jobtip.png",
          image_hover: "/img/images/portfolio/main/Jobtip_clear.png",
          title: "Jobtip",
          url: "/portfolio/jobtip",
          tags: [
            {
              name: "#Redesign"
            },
            {
              name: "#Landing Page"
            },
            {
              name: "#Rebranding"
            },
            {
              name: "#Illustrations"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/health_wizz.png",
          image_hover: "/img/images/portfolio/main/health_wizz_clear.png",
          title: "Health Wizz",
          url: "/portfolio/health",
          tags: [
            {
              name: "#Webapp"
            },
            {
              name: "#Blockchain"
            },
            {
              name: "#Healthcare"
            },
            {
              name: "#Design System"
            },
            {
              name: "#SaaS"
            },
            {
              name: "#Fintech"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Human_capital.png",
          image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
          title: "Human Capital",
          url: "/portfolio/human-capital",
          tags: [
            {
              name: "#Web Site"
            },
            {
              name: "#UX Research"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#Recruitment"
            }
          ]
        }
      ]
    };
  }
};
</script>