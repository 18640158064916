require("./bootstrap");

import Vue from "vue";
import VueRouter from "vue-router";
import router from "./routes";
import App from "./components/MainApp";
import { i18n } from "./plugins/i18n";

Vue.use(VueRouter);

const app = new Vue({
    el: "#app",
    router,
    i18n,
    render: h => h(App)
});
