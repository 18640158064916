<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/Health_Wizz/Mobile/HW_header_img_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Health_Wizz/Mobile/HW_header_img_mobile-iphone.png"
          media="(max-width: 414px)"
        />
        <source
          srcset="/img/images/portfolio/Health_Wizz/header_image.png"
          media="(max-width: 1699px)"
        />
        <source
          srcset="/img/images/portfolio/Health_Wizz/bg_image_health_wizz-full-hd.png"
          media="(min-width: 1700px)"
        />
        <img
          class="main-img_portfolio main-img_health"
          src="/img/images/portfolio/Health_Wizz/bg_image_health_wizz-full-hd.png"
          alt
        />
      </picture>
      <section class="wrapper current-portfolio current-portfolio_health">
        <h1 class="current-portfolio_title">{{$t("health-wizz.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("health-wizz.hash-tag1")}}</li>
          <li>{{$t("health-wizz.hash-tag2")}}</li>
          <li>{{$t("health-wizz.hash-tag3")}}</li>
          <li>{{$t("health-wizz.hash-tag4")}}</li>
          <li>{{$t("health-wizz.hash-tag5")}}</li>
          <li>{{$t("health-wizz.hash-tag6")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("health-wizz.name")}}</em>
          {{$t("health-wizz.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("health-wizz.description")}}</p>
      </section>
      <div class="wrapper wrapper-health">
        <picture>
          <source
            srcset="/img/images/portfolio/Health_Wizz/Mobile/Bg_img_1.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Health_Wizz/Mobile/bg_1-iphone.png"
            media="(max-width: 414px)"
          />

          <img
            class="health-img_challenge"
            src="/img/images/portfolio/Health_Wizz/bg_img_2.png"
            alt
          />
        </picture>
        <picture>
          <source
            srcset="/img/images/portfolio/Health_Wizz/Mobile/Bg_img_1.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Health_Wizz/Mobile/bg_2-iphone.png"
            media="(max-width: 414px)"
          />

          <img class="health-img_app" src="/img/images/portfolio/Health_Wizz/bg_img_3.png" alt />
        </picture>
        <div class="portfolio-description health-chellenge_desc">
          <section>
            <h2>{{$t("health-wizz.challenges")}}</h2>
            <div class="horizontal-line"></div>
            <p>
                <i18n path="health-wizz.text1">
              <a target="_blank" href="https://www.healthwizz.com/" place="action">{{$t("health-wizz.name")}}</a>
              </i18n>
            </p>
            <br />
            <p>
              {{$t("health-wizz.text2")}
            </p>
            <br />
            <p>
             <i18n path="health-wizz.text3">
                 <br place="break" />
             </i18n>
            </p>
            <br />
            <p>
              {{$t("health-wizz.text4")}}
            </p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Health_Wizz/Mobile/Goals.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Health_Wizz/Mobile/Screen_Shot-iphone.png"
              media="(max-width: 414px)"
            />

            <img class="health-img_goals" src="/img/images/portfolio/Health_Wizz/Goals.png" alt />
          </picture>
        </div>
        <div class="portfolio-description health-description_design">
          <section>
            <h2>{{$t("health-wizz.web")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              {{$t("health-wizz.text5")}}
            </p>
            <br />
            <p>
              {{$t("health-wizz.text6")}}
            </p>
            <br />
            <p>
              {{$t("health-wizz.text7")}}
            </p>
            <p>
              <br />{{$t("health-wizz.text8")}}
            </p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_1.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_1-iphone.png"
              media="(max-width: 414px)"
            />

            <img class="health-design-1" src="/img/images/portfolio/Health_Wizz/web_app_1.png" alt />
          </picture>

          <picture>
            <source
              srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_2.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_2-iphone.png"
              media="(max-width: 414px)"
            />

            <img class="health-design-2" src="/img/images/portfolio/Health_Wizz/Web_app_2.png" alt />
          </picture>
        </div>

        <div class="portfolio-description mobile-app_description">
          <section>
            <h2>{{$t("health-wizz.mobile-app")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              {{$t("health-wizz.text9")}}
            </p>
            <br />
            <p>
             {{$t("health-wizz.text10")}}
            </p>
            <br />
            <p>
             {{$t("health-wizz.text11")}}
            </p>
            <br />
            <p>
              {{$t("health-wizz.text12")}}
            </p>
          </section>
          <img
            class="app-design_img"
            src="/img/images/portfolio/Health_Wizz/Mobile_app_design.png"
            alt
          />
        </div>
      </div>
    </div>

    <section class="more-projects ember-more_projects">
      <h2 class="main-title">More projects</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "alacris",
  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Human_capital.png",
          image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
          title: "Human Capital",
          url: "/portfolio/human-capital",
          tags: [
            {
              name: "#Web Site"
            },
            {
              name: "#UX Research"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#Recruitment"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Infra.png",
          image_hover: "/img/images/portfolio/main/Infra_clear.png",
          title: "Infra",
          url: "/portfolio/infra",
          tags: [
            {
              name: "#Redesign"
            },
            {
              name: "#Rebranding"
            },
            {
              name: "#Landing page"
            },
            {
              name: "#Marketing design"
            },
            {
              name: "#SaaS"
            },
            {
              name: "#Cyber Security"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Earth_rating.png",
          image_hover: "/img/images/portfolio/main/Earth_rating_clear.png",
          title: "Earth Rating",
          url: "/portfolio/rating",
          tags: [
            {
              name: "#Web App Prototyping"
            },
            {
              name: "#Presentation"
            },
            {
              name: "#Ecommerce"
            },
            {
              name: "#Environment"
            }
          ]
        }
      ]
    };
  }
};
</script>