<template>
  <div>
    <div class="wrap-get-estimate">
      <div class="wrapper">
        <section class="get-free-estimate">
          <h1 class="title-get-free-estimate">{{$t("form.get-estimate")}}</h1>
          <h2 class="form-title">{{$t("form.fill-form")}}</h2>
          <form action="#" class="form-wrapper">
            <div class="input-textarea-wrapper">
              <div class="input-wrapper">
                <p>
                  <label v-bind:style="styles.name.label" for="name">{{$t("form.name")}}</label>
                  <input
                    :style="styles.name.input"
                    type="text"
                    id="name"
                    v-model="mailData.name"
                    @click="delErrors('name')"
                    @blur="checkForEmptiness('name')"
                  />
                </p>
                <p>
                  <label v-bind:style="styles.company.label" for="company">{{$t("form.company")}}</label>
                  <input
                    :style="styles.company.input"
                    type="text"
                    id="company"
                    v-model="mailData.company"
                    @click="delErrors('company')"
                    @blur="checkForEmptiness('company')"
                  />
                </p>
                <p>
                  <label v-bind:style="styles.email.label" for="email">{{$t("form.email")}}</label>
                  <input
                    :style="styles.email.input"
                    type="text"
                    id="email"
                    v-model="mailData.email"
                    @click="delErrors('email')"
                    @blur="checkForEmptiness('email')"
                  />
                </p>
              </div>
              <p class="textarea-wrapper">
                <label v-bind:style="styles.text.label" for="textarea" v-html="lables.text"></label>
                <textarea
                  :style="styles.text.input"
                  name
                  id="textarea"
                  cols="35"
                  rows="24"
                  v-model="mailData.text"
                  @click="delErrors('text')"
                  @blur="checkForEmptiness('text')"
                ></textarea>
              </p>
              <p></p>
            </div>
            <div class="bottom-form-wrapper">
              <label class="checkbox">
                <input type="checkbox" @click="checkedUpdate()" />
                <span class="custom-checkbox" ref="checkbox">
                  <img src="/img/icons/checked.svg" alt />
                </span>
                <div class="checkbox-text">
                  {{$t("form.agreement")}}
                  <a href="/privacy-policy">{{$t("form.link")}}</a>
                  {{$t("form.after-link")}}
                </div>
              </label>
              <button
                type="submit"
                v-on:click="sendMail()"
                class="submit-btn"
                :class="{'disabled-btn': isDisabled}"
              >{{$t("form.send")}}</button>
            </div>
          </form>
          <p class="or">{{$t("form.or")}}</p>
          <a
            href="https://calendly.com/bozkagames/30min?month=2019-09"
            class="schedule-meeting-link"
          >{{$t("form.schedule-meeting")}}</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { requestGet, requestPost } from "../services/axios.js";
let vm = {};
export default {
  name: "get-estimate",
  components: {},
  data() {
    return {
      mailData: {
        name: "",
        company: "",
        text: "",
        email: ""
      },
      errors: [],
      lables: {
        text: `Tell about your project 
              <br />(problems, ideas, timeline, budget)`
      },
      styles: {
        name: {
          input: "",
          label: ""
        },
        company: {
          input: "",
          label: ""
        },
        text: {
          input: "",
          label: ""
        },
        email: {
          input: "",
          label: ""
        }
      },
      checked: false
    };
  },
  beforeCreate() {
    vm = this;
  },
  computed: {
    isDisabled: function() {
      return !vm.checked;
    }
  },
  methods: {
    // checkedUpdate: () => (vm.checked = !vm.checked),
    checkedUpdate: () => {
      vm.checked = !vm.checked;
      console.log("checked ", vm.checked);
    },
    checkForEmptiness: (field, event) => {
      if (vm.mailData[field] === "") {
        vm.styles[field]["label"] =
          "transform: translateY(0); font-size: 14px;";
        if (field === "text") {
          vm.lables.text = `Tell about your project 
              <br />(problems, ideas, timeline, budget)`;
        }
      }
    },
    delErrors: field => {
      if (field == "text") {
        vm.lables.text = "Tell about your project ";
      }
      vm.styles[field]["label"] =
        "transform: translateY(-25px); font-size: 10px;";
      vm.styles[field]["input"] = "";
    },
    validEmail: email => {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    hasErrors: () => {
      let gotError = false;
      Object.keys(vm.mailData).forEach(item => {
        if (vm.mailData[item] == "") {
          gotError = true;
          vm.styles[item]["label"] += "color: #FF7F5F;";
          vm.styles[item]["input"] += "border-bottom-color: #FF7F5F;";
        } else if (item == "email" && !vm.validEmail(vm.mailData[item])) {
          gotError = true;
          vm.styles[item]["label"] += "color: #FF7F5F;";
          vm.styles[item]["input"] += "border-bottom-color: #FF7F5F;";
        }
      });

      return gotError;
    },
    sendMail: () => {
      if (!vm.checked) {
        event.preventDefault();
        vm.$refs.checkbox.classList.add("shake");
        setTimeout(() => {
          vm.$refs.checkbox.classList.remove("shake");
        }, 500)
        return;
      }

      if (!vm.hasErrors()) {
        event.preventDefault();
        requestPost("/contact-us", vm.mailData)
          .then(res => {
            if (res.status) {
              vm.$router.push("/successfully-send");
            } else {
              console.log("Email send failed, error", res.error);
              console.log("resend email");
              vm.sendMail();
            }
          })
          .catch(error => {
            return { status: false, error };
          });
      }
    }
  }
};
</script>