<template>
    <!--SECTION ROADMAP-->
    
    <div class="section-roadmap" id="section-roadmap">
        <div class="wrapper">
            <h2 class="main-title">{{$t("section.roadmap")}}</h2>
            <h3 class="main-subtitle">{{$t("roadmap.roadmap-new_subtitle")}}</h3>
           
                 <!-- <img class="roadmap-img roadmap-img-main" src="/img/images/roadmap-main.svg" width="1237" height="430" alt="roadmap" usemap="#Navigation"> -->
                <div class="roadmap-img-wrapper roadmap-main">  
                    <div class="iterations-1">
                    <p>iterations</p>           
                    </div>         
                    <div class="iterations-2">
                    <p>iterations</p>           
                    </div>         
                    <div class="research">
                    <p>Research</p>           
                    </div>         
                    <div class="mapping">
                    <p>Mapping</p>           
                    </div>         
                    <div class="prototyping">
                    <p>Prototyping</p>           
                    </div>         
                    <div class="problem">
                    <p>Problem</p>           
                    <p>Analysis</p>  
                    </div>         
                    <div class="initial">
                    <p>Initial User</p>           
                    <p>Interview</p>  
                    </div>         
                    <div class="personas">
                    <p>User</p>           
                    <p>Personas</p>  
                    </div>         
                    <div class="journey">
                    <p>User</p>           
                    <p>Journey</p>  
                    </div>         
                    <div class="flow">
                    <p>Flow</p>           
                    <p>Map</p>  
                    </div>         
                    <div class="wire">
                    <p>Wire-</p>           
                    <p>frames</p>  
                    </div>         
                    <div class="ui">
                    <p>UI</p>           
                    <p>Mockup</p>  
                    </div>         
                    <div class="solution text-white">
                    <p>Solution</p>           
                    <p>Ideation</p>  
                    </div>         
                    <div class="testing text-white">
                    <p>Testing</p>           
                    </div>         
                 <img class="roadmap-img roadmap-img-main" src="/img/images/roadmap-main.svg" width="1237" height="430" alt="roadmap" usemap="#Navigation">
                    <map name="Navigation">
                          <area class="area" coords="132.5,202.5,30" shape="circle"  alt="Problem-Analysis">
                            <div class="roadmap-popup roadmap-popup-1">
                                <h3>{{$t("roadmap.problem-title")}}</h3>
                                <p>{{$t("roadmap.problem-text")}}</p>
                            </div>
                        <area class="area" coords="263,202.5,30" shape="circle"  alt="Initial-User">
                            <div class="roadmap-popup roadmap-popup-2">
                                <h3>{{$t("roadmap.interview-title")}}</h3>
                                <p>{{$t("roadmap.interview-text")}}</p>
                            </div>
                        <area class="area" coords="197.5,281.5,30" shape="circle" alt="User-Personas">
                            <div class="roadmap-popup roadmap-popup-3">
                                <h3>{{$t("roadmap.personas-title")}}</h3>
                                <p>{{$t("roadmap.personas-text")}}</p>
                            </div>                     
                        <area class="area" coords="558.5,214.5,30" shape="circle" alt="User-Journey">
                            <div class="roadmap-popup roadmap-popup-4">
                                <h3>{{$t("roadmap.journey-title")}}</h3>
                                <p>{{$t("roadmap.journey-text")}}</p>
                            </div> 
                        <area class="area" coords="678,214.5,30" shape="circle" alt="Flow-Map">
                            <div class="roadmap-popup roadmap-popup-5">
                                <h3>{{$t("roadmap.map-title")}}</h3>
                                <p>{{$t("roadmap.map-text")}}</p>
                            </div>
                        <area class="area" coords="832.5,214.5,30" shape="circle" alt="Wire-frames">
                            <div class="roadmap-popup roadmap-popup-6">
                                <h3>{{$t("roadmap.wireframes-title")}}</h3>
                                <p>{{$t("roadmap.wireframes-text")}}</p>
                            </div>
                        <area class="area" coords="952,214.5,30" shape="circle" alt="UI-Mockup">
                            <div class="roadmap-popup roadmap-popup-7">
                                <h3>{{$t("roadmap.mockup-title")}}</h3>
                                <p>{{$t("roadmap.mockup-text")}}</p>
                            </div>
                        <area class="area" coords="410,214.5,30" shape="circle" alt="Solution-Ideation">
                            <div class="roadmap-popup roadmap-popup-8">
                                <h3>{{$t("roadmap.solution-title")}}</h3>
                                <p>{{$t("roadmap.solution-text")}}</p>
                            </div>
                        <area class="area" coords="1100,214.5,30" shape="circle" alt="Testing">
                            <div class="roadmap-popup roadmap-popup-9">
                                <h3>{{$t("roadmap.testing-title")}}</h3>
                                <p>{{$t("roadmap.testing-text")}}</p>
                            </div>
                    </map>
                </div>

                <div class="roadmap-img-wrapper roadmap-1024">
                    <div class="iterations-1">
                    <p>iterations</p>           
                    </div>         
                    <div class="iterations-2">
                    <p>iterations</p>           
                    </div>         
                    <div class="research">
                    <p>Research</p>           
                    </div>         
                    <div class="mapping">
                    <p>Mapping</p>           
                    </div>         
                    <div class="prototyping">
                    <p>Prototyping</p>           
                    </div>         
                    <div class="problem">
                    <p>Problem</p>           
                    <p>Analysis</p>  
                    </div>         
                    <div class="initial">
                    <p>Initial User</p>           
                    <p>Interview</p>  
                    </div>         
                    <div class="personas">
                    <p>User</p>           
                    <p>Personas</p>  
                    </div>         
                    <div class="journey">
                    <p>User</p>           
                    <p>Journey</p>  
                    </div>         
                    <div class="flow">
                    <p>Flow</p>           
                    <p>Map</p>  
                    </div>         
                    <div class="wire">
                    <p>Wire-</p>           
                    <p>frames</p>  
                    </div>         
                    <div class="ui">
                    <p>UI</p>           
                    <p>Mockup</p>  
                    </div>         
                    <div class="solution text-white">
                    <p>Solution</p>           
                    <p>Ideation</p>  
                    </div>         
                    <div class="testing text-white">
                    <p>Testing</p>           
                    </div>
                <img class="roadmap-img roadmap-img-1024" src="/img/images/Road-Map-1024.svg" width="936" height="755" alt="roadmap" usemap="#Navigation-1024">
                    <map name="Navigation-1024">
                        <area class="area" coords="385,158,30" shape="circle"  alt="Problem-Analysis">
                            <div class="roadmap-popup roadmap-popup-2-1">
                                <h3>{{$t("roadmap.problem-title")}}</h3>
                                <p>{{$t("roadmap.problem-text")}}</p>
                            </div>
                        <area class="area" coords="515,158,30" shape="circle"  alt="Initial-User">
                            <div class="roadmap-popup roadmap-popup-2-2">
                                <h3>{{$t("roadmap.interview-title")}}</h3>
                                <p>{{$t("roadmap.interview-text")}}</p>
                            </div>
                        <area class="area" coords="453,240,30" shape="circle" alt="User-Personas">
                            <div class="roadmap-popup roadmap-popup-2-3">
                                <h3>{{$t("roadmap.personas-title")}}</h3>
                                <p>{{$t("roadmap.personas-text")}}</p>
                            </div>                     
                        <area class="area" coords="252,535,30" shape="circle" alt="User-Journey">
                            <div class="roadmap-popup roadmap-popup-2-4">
                                <h3>{{$t("roadmap.journey-title")}}</h3>
                                <p>{{$t("roadmap.journey-text")}}</p>
                            </div> 
                        <area class="area" coords="372,535,30" shape="circle" alt="Flow-Map">
                            <div class="roadmap-popup roadmap-popup-2-5">
                                <h3>{{$t("roadmap.map-title")}}</h3>
                                <p>{{$t("roadmap.map-text")}}</p>
                            </div>
                        <area class="area" coords="525,535,30" shape="circle" alt="Wire-frames">
                            <div class="roadmap-popup roadmap-popup-2-6">
                                <h3>{{$t("roadmap.wireframes-title")}}</h3>
                                <p>{{$t("roadmap.wireframes-text")}}</p>
                            </div>
                        <area class="area" coords="645,535,30" shape="circle" alt="UI-Mockup">
                            <div class="roadmap-popup roadmap-popup-2-7">
                                <h3>{{$t("roadmap.mockup-title")}}</h3>
                                <p>{{$t("roadmap.mockup-text")}}</p>
                            </div>
                        <area class="area" coords="100,535,30" shape="circle" alt="Solution-Ideation">
                            <div class="roadmap-popup roadmap-popup-2-8">
                                <h3>{{$t("roadmap.solution-title")}}</h3>
                                <p>{{$t("roadmap.solution-text")}}</p>
                            </div>
                        <area class="area" coords="790,535,30" shape="circle" alt="Testing">
                            <div class="roadmap-popup roadmap-popup-2-9">
                                <h3>{{$t("roadmap.testing-title")}}</h3>
                                <p>{{$t("roadmap.testing-text")}}</p>
                            </div>
                    </map>
                </div>

                <div class="roadmap-img-wrapper roadmap-768">
                    <div class="iterations-1">
                    <p>iterations</p>           
                    </div>         
                    <div class="iterations-2">
                    <p>iterations</p>           
                    </div>         
                    <div class="research">
                    <p>Research</p>           
                    </div>         
                    <div class="mapping">
                    <p>Mapping</p>           
                    </div>         
                    <div class="prototyping">
                    <p>Prototyping</p>           
                    </div>         
                    <div class="problem">
                    <p>Problem</p>           
                    <p>Analysis</p>  
                    </div>         
                    <div class="initial">
                    <p>Initial User</p>           
                    <p>Interview</p>  
                    </div>         
                    <div class="personas">
                    <p>User</p>           
                    <p>Personas</p>  
                    </div>         
                    <div class="journey">
                    <p>User</p>           
                    <p>Journey</p>  
                    </div>         
                    <div class="flow">
                    <p>Flow</p>           
                    <p>Map</p>  
                    </div>         
                    <div class="wire">
                    <p>Wire-</p>           
                    <p>frames</p>  
                    </div>         
                    <div class="ui">
                    <p>UI</p>           
                    <p>Mockup</p>  
                    </div>         
                    <div class="solution text-white">
                    <p>Solution</p>           
                    <p>Ideation</p>  
                    </div>         
                    <div class="testing text-white">
                    <p>Testing</p>           
                    </div>
                    <img class="roadmap-img roadmap-img-768" src="/img/images/Road-Map-768.svg" width="641" height="1010" alt="roadmap" usemap="#Navigation-768">
                     <map name="Navigation-768">
                        <area class="area" coords="265,170,35" shape="circle"  alt="Problem-Analysis">
                            <div class="roadmap-popup roadmap-popup-3-1">
                                <h3>{{$t("roadmap.problem-title")}}</h3>
                                <p>{{$t("roadmap.problem-text")}}</p>
                            </div>
                        <area class="area" coords="395,170,35" shape="circle"  alt="Initial-User">
                            <div class="roadmap-popup roadmap-popup-3-2">
                                <h3>{{$t("roadmap.interview-title")}}</h3>
                                <p>{{$t("roadmap.interview-text")}}</p>
                            </div>
                        <area class="area" coords="332,255,35" shape="circle" alt="User-Personas">
                            <div class="roadmap-popup roadmap-popup-3-3">
                                <h3>{{$t("roadmap.personas-title")}}</h3>
                                <p>{{$t("roadmap.personas-text")}}</p>
                            </div>                     
                        <area class="area" coords="126,615,35" shape="circle" alt="User-Journey">
                            <div class="roadmap-popup roadmap-popup-3-4">
                                <h3>{{$t("roadmap.journey-title")}}</h3>
                                <p>{{$t("roadmap.journey-text")}}</p>
                            </div> 
                        <area class="area" coords="245,615,35" shape="circle" alt="Flow-Map">
                            <div class="roadmap-popup roadmap-popup-3-5">
                                <h3>{{$t("roadmap.map-title")}}</h3>
                                <p>{{$t("roadmap.map-text")}}</p>
                            </div>
                        <area class="area" coords="400,620,30" shape="circle" alt="Wire-frames">
                            <div class="roadmap-popup roadmap-popup-3-6">
                                <h3>{{$t("roadmap.wireframes-title")}}</h3>
                                <p>{{$t("roadmap.wireframes-text")}}</p>
                            </div>
                        <area class="area" coords="520,620,30" shape="circle" alt="UI-Mockup">
                            <div class="roadmap-popup roadmap-popup-3-7">
                                <h3>{{$t("roadmap.mockup-title")}}</h3>
                                <p>{{$t("roadmap.mockup-text")}}</p>
                            </div>
                        <area class="area" coords="332,395,35" shape="circle" alt="Solution-Ideation">
                            <div class="roadmap-popup roadmap-popup-3-8">
                                <h3>{{$t("roadmap.solution-title")}}</h3>
                                <p>{{$t("roadmap.solution-text")}}</p>
                            </div>
                        <area class="area" coords="332,880,30" shape="circle" alt="Testing">
                            <div class="roadmap-popup roadmap-popup-3-9">
                                <h3>{{$t("roadmap.testing-title")}}</h3>
                                <p>{{$t("roadmap.testing-text")}}</p>
                            </div>
                    </map>
                </div>
                <div class="roadmap-mobile-wrapper" id="roadmap-mobile-wrapper">
                <div class="roadmap-img-wrapper roadmap-mobile" id="roadmap-mobile">
                    <div class="iterations-1">
                    <p>iterations</p>           
                    </div>         
                    <div class="iterations-2">
                    <p>iterations</p>           
                    </div>         
                    <div class="research">
                    <p>Research</p>           
                    </div>         
                    <div class="mapping">
                    <p>Mapping</p>           
                    </div>         
                    <div class="prototyping">
                    <p>Prototyping</p>           
                    </div>        

                    <div class="problem mobile-title">
                    <p>Problem</p>           
                    <p>Analysis</p>
                    </div> 

                <input class="modal-open" id="modal-1" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">
                            <div class="roadmap-popup roadmap-popup-4-1">
                                <h3>{{$t("roadmap.problem-title")}}</h3>
                                <p>{{$t("roadmap.problem-text")}}</p>
                            </div>

                    <div class="initial mobile-title">
                    <p>Initial User</p>           
                    <p>Interview</p>  
                    </div>

                <input class="modal-open" id="modal-2" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">
                            <div class="roadmap-popup roadmap-popup-4-2">
                                <h3>{{$t("roadmap.interview-title")}}</h3>
                                <p>{{$t("roadmap.interview-text")}}</p>
                            </div>

                    <div class="personas mobile-title">
                    <p>User</p>           
                    <p>Personas</p>  
                    </div>  

                <input class="modal-open" id="modal-3" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">
                            <div class="roadmap-popup roadmap-popup-4-3">
                                <h3>{{$t("roadmap.personas-title")}}</h3>
                                <p>{{$t("roadmap.personas-text")}}</p>
                            </div>                     

                    <div class="journey mobile-title">
                    <p>User</p>           
                    <p>Journey</p>  
                    </div> 
                <input class="modal-open" id="modal-4" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">
                            <div class="roadmap-popup roadmap-popup-4-4">
                                <h3>{{$t("roadmap.journey-title")}}</h3>
                                <p>{{$t("roadmap.journey-text")}}</p>
                            </div>
                    <div class="flow mobile-title">
                    <p>Flow</p>           
                    <p>Map</p>  
                    </div>
                <input class="modal-open" id="modal-5" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">
                            <div class="roadmap-popup roadmap-popup-4-5">
                                <h3>{{$t("roadmap.map-title")}}</h3>
                                <p>{{$t("roadmap.map-text")}}</p>
                            </div>
                    <div class="wire mobile-title">
                    <p>Wire-</p>           
                    <p>frames</p>  
                    </div>
                <input class="modal-open" id="modal-6" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">                     
                            <div class="roadmap-popup roadmap-popup-4-6">
                                <h3>{{$t("roadmap.mockup-title")}}</h3>
                                <p>{{$t("roadmap.mockup-text")}}</p>
                            </div> 
                    <div class="solution text-white mobile-title">
                    <p>Solution</p>           
                    <p>Ideation</p>  
                    </div>
                <input class="modal-open" id="modal-7" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">                  
                            <div class="roadmap-popup roadmap-popup-4-7">
                                <h3>{{$t("roadmap.wireframes-title")}}</h3>
                                <p>{{$t("roadmap.wireframes-text")}}</p>
                            </div>
                    <div class="ui mobile-title">
                    <p>UI</p>           
                    <p>Mockup</p>  
                    </div>
                
                <input class="modal-open" id="modal-8" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">                    
                            <div class="roadmap-popup roadmap-popup-4-8">
                                <h3>{{$t("roadmap.solution-title")}}</h3>
                                <p>{{$t("roadmap.solution-text")}}</p>
                            </div>
                    <div class="testing text-white mobile-title">
                    <p>Testing</p>           
                    </div>
                <input class="modal-open" id="modal-9" type="radio" name="radio" onmousedown="this.isChecked=this.checked;" onclick="this.checked=!this.isChecked;">                    
                            <div class="roadmap-popup roadmap-popup-4-9">
                                <h3>{{$t("roadmap.testing-title")}}</h3>
                                <p>{{$t("roadmap.testing-text")}}</p>
                            </div>                    

                    <img class="roadmap-img roadmap-img-mobile" src="/img/images/Road-map-mobile.svg" width="315" height="1244" alt="roadmap" usemap="#Navigation-mobile">
                     <map name="Navigation-mobile">
                        <area class="area" coords="92,162,35" shape="circle"  alt="Problem-Analysis">
                            <div class="roadmap-popup roadmap-popup-4-1">
                                <h3>{{$t("roadmap.problem-title")}}</h3>
                                <p>{{$t("roadmap.problem-text")}}</p>
                            </div>
                        <area class="area" coords="218,162,35" shape="circle"  alt="Initial-User">
                            <div class="roadmap-popup roadmap-popup-4-2">
                                <h3>{{$t("roadmap.interview-title")}}</h3>
                                <p>{{$t("roadmap.interview-text")}}</p>
                            </div>
                        <area class="area" coords="152,240,35" shape="circle" alt="User-Personas">
                            <div class="roadmap-popup roadmap-popup-4-3">
                                <h3>{{$t("roadmap.personas-title")}}</h3>
                                <p>{{$t("roadmap.personas-text")}}</p>
                            </div>
                        <area class="area" coords="92,620,30" shape="circle" alt="User-Journey">
                            <div class="roadmap-popup roadmap-popup-4-4">
                                <h3>{{$t("roadmap.journey-title")}}</h3>
                                <p>{{$t("roadmap.journey-text")}}</p>
                            </div> 
                        <area class="area" coords="215,620,30" shape="circle" alt="Flow-Map">
                            <div class="roadmap-popup roadmap-popup-4-5">
                                <h3>{{$t("roadmap.map-title")}}</h3>
                                <p>{{$t("roadmap.map-text")}}</p>
                            </div>
                         <area class="area" coords="92,895,30" shape="circle" alt="UI-Mockup">
                            <div class="roadmap-popup roadmap-popup-4-7">
                                <h3>{{$t("roadmap.mockup-title")}}</h3>
                                <p>{{$t("roadmap.mockup-text")}}</p>
                            </div>                            
                        <area class="area" coords="215,895,30" shape="circle" alt="Wire-frames">
                            <div class="roadmap-popup roadmap-popup-4-6">
                                <h3>{{$t("roadmap.wireframes-title")}}</h3>
                                <p>{{$t("roadmap.wireframes-text")}}</p>
                            </div>
                        <area class="area" coords="152,380,35" shape="circle" alt="Solution-Ideation">
                            <div class="roadmap-popup roadmap-popup-4-8">
                                <h3>{{$t("roadmap.solution-title")}}</h3>
                                <p>{{$t("roadmap.solution-text")}}</p>
                            </div>
                        <area class="area" coords="155,1098,30" shape="circle" alt="Testing">
                            <div class="roadmap-popup roadmap-popup-4-9">
                                <h3>{{$t("roadmap.testing-title")}}</h3>
                                <p>{{$t("roadmap.testing-text")}}</p>
                            </div>
                    </map>
                </div>  
               </div>
                
         </div>
    </div>
</template>

<script>

    import { ROADMAP } from '../constants/consts';

    export default {
        name: "roadmap",
        data(){
            return { ROADMAP }
        },
        mounted() {
            
              let roadmap=document.getElementById('roadmap-mobile-wrapper'),
              input=document.querySelectorAll('.modal-open'),
              modals=document.querySelectorAll('.roadmap-popup');
                      roadmap.onmousedown = function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                     if ($(e.target).is('input')) {
                         for (let i = 0; i < modals.length; i++) {
                            modals[i].style.display = "none";
                           }
                    (e.target).nextElementSibling.style.display = "block";
                 }else{
                for (let i = 0; i < modals.length; i++) {
                modals[i].style.display = "none";
                 }
                 }
          };
        }


    }

</script>

