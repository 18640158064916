<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/Human_capital/Human_capital_Header_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Human_capital/Human_capital_Header_mobile-iphone.png"
          media="(max-width: 414px)"
        />
        <source
          srcset="/img/images/portfolio/Human_capital/Human_capital_header.png"
          media="(max-width: 1699px)"
        />
        <source
          srcset="/img/images/portfolio/Human_capital/Human_Capital-full-hd.png"
          media="(min-width: 1700px)"
        />

        <img
          class="main-img_portfolio main-img_human"
          src="/img/images/portfolio/Human_capital/Human_Capital-full-hd.png"
          alt
        />
      </picture>
      <section class="wrapper current-portfolio current-human_capital">
        <h1 class="current-portfolio_title">{{$t("human-capital.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("human-capital.hash-tag1")}}</li>
          <li>{{$t("human-capital.hash-tag2")}}</li>
          <li>{{$t("human-capital.hash-tag3")}}</li>
          <li>{{$t("human-capital.hash-tag4")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("human-capital.name")}}</em>
          {{$t("human-capital.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("human-capital.description")}}</p>
      </section>
      <img class="main-bg_human" src="/img/images/portfolio/Human_capital/bg_image_1.png" alt />
      <div class="wrapper wrapper-human_capital">
        <div class="portfolio-description human-capital_description">
          <section>
            <h2>{{$t("human-capital.analysis")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              <i18n path="human-capital.text1">
                <a
                  target="_blank"
                  place="action"
                  href="https://human-capital.com.ua/"
                >{{$t("human-capital.name")}}</a>
              </i18n>
            </p>
            <br />
            <p>{{$t("human-capital.text2")}}</p>
          </section>
        </div>

        <picture>
          <source
            srcset="/img/images/portfolio/Human_capital/analysis_mobile.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Human_capital/analysis-iphone.png"
            media="(max-width: 414px)"
          />

          <img
            class="human-capital_analysis"
            src="/img/images/portfolio/Human_capital/analysis.png"
            alt
          />
        </picture>
        <div class="portfolio-description">
          <section>
            <h2>{{$t("human-capital.testing")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("human-capital.text3")}}</p>
          </section>
        </div>

        <picture>
          <source
            srcset="/img/images/portfolio/Human_capital/testing_mobile.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Human_capital/testing-iphone.png"
            media="(max-width: 414px)"
          />

          <img class="human-testing_img" src="/img/images/portfolio/Human_capital/testing.png" alt />
        </picture>

        <img class="main-bg_human-2" src="/img/images/portfolio/Human_capital/bg_image_2.png" alt />

        <div class="portfolio-description human-capital_result">
          <section>
            <h2>{{$t("human-capital.result")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("human-capital.text4")}}</p>
          </section>
        </div>
        <picture>
          <source
            srcset="/img/images/portfolio/Human_capital/result_mobile.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/Human_capital/result-iphone.png"
            media="(max-width: 414px)"
          />

          <img
            class="human-capital_result-img"
            src="/img/images/portfolio/Human_capital/result.png"
            alt
          />
        </picture>
      </div>
    </div>

    <section class="more-projects">
      <h2 class="main-title">{{$t("more-projects")}}</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "human-capital",

  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Ember.png",
          image_hover: "/img/images/portfolio/main/Ember_clear.png",
          title: "Embr Wave",
          url: "/portfolio/ember",
          tags: [
            {
              name: "#Design Support"
            },
            {
              name: "#Mobile App"
            },
            {
              name: "#IoT"
            },
            {
              name: "#Wearables"
            },
            {
              name: "#User Research"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Stressguide.png",
          image_hover: "/img/images/portfolio/main/Stressguide_clear.png",
          title: "Stress Guide",
          url: "/portfolio/stress-guide",
          tags: [
            {
              name: "#Mobile App"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#User Research"
            },
            {
              name: "#Healthcare"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Alacris.png",
          image_hover: "/img/images/portfolio/main/Alacris_clear.png",
          title: "Alacris",
          url: "/portfolio/alacris",
          tags: [
            {
              name: "#Design Support"
            },
            {
              name: "#Demo Prototype"
            },
            {
              name: "#Fintech"
            },
            {
              name: "#Blockchain"
            },
            {
              name: "#Landing Page"
            }
          ]
        }
      ]
    };
  }
};
</script>