<template>
    <div class="section-not_fount">
        <img  src="/img/images/404.png" alt="">
        <h2>404 Page not found.</h2>
    </div>
</template>

<script>

    export default {
        name: 'not-found',

        data(){
            return {

            }
        },
    }
</script>