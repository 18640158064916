export const listPackages = (vm) => {
    return [
        {
            id: 1,
            question: "packages-my.mvp-design",
            answer: `
          <div class="packages-top-card-wrapper">
              <div class="packages-top-card card-one">
               <h4>${vm.$t("packages-my.for-whom")}</h4>
               <p>${vm.$t("packages-my.for-startups")}</p>
              </div>
              <div class="packages-top-card card-two">
              <h4>${vm.$t("packages-my.what-kind")}</h4>
              <p>${vm.$t("packages-my.saas-web")}</p>
              </div>
          </div>
          <div class="packages-central-card-wrapper">
              <div class="packages-central-card central-card-one">
                <div class="central-card-header">
                 <h4>${vm.$t("packages-my.basic")}</h4>
                 <p>${vm.$t("packages-my.works-well")}</p>
                </div>
                <div class="central-card-main">
                    <ul class="central-card-main-list">
                      <li>${vm.$t("packages-my.research-audit")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img img-1" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                <p class="packages-popup-title">${vm.$t("packages-my.research-audit")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.research-text")}</p>
                              </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.user-personas")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.user-personas")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.user-personas-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.user-doc")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.user-journey")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.user-journey")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.user-journey-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.user-map")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.initial-user-interview")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.initial-user-interview")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.initial-user-interview-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.definition")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.flow-map")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.flow-map")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.flow-map-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.flow-miro")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.user-stories")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.user-stories")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.user-stories-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.user-storiies-doc")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.wireframes")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">

                                <p class="packages-popup-title">${vm.$t("packages-my.wireframes")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.wireframes-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                       </li>
                      <li>${vm.$t("packages-my.ui-mockup")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-mockup")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.ui-mockup-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-styleguide")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-styleguide")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.ui-styleguide-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch-file")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.user-testing")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.user-testing")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.user-testing-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.analysis")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.documentation")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.documentation")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.documentation-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.document")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                  </ul>



                              <div class="packages-popup popup-1">
                                <p class="packages-popup-title">${vm.$t("packages-my.research-audit")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.research-text")}</p>
                              </div>
                              <div class="packages-popup popup-1">
                                <p class="packages-popup-title">${vm.$t("packages-my.wireframes")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.wireframes-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                              <div class="packages-popup popup-1">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-mockup")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.ui-mockup-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                              <div class="packages-popup popup-1">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-styleguide")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.ui-styleguide-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch-file")}</span></li>
                              </ul>
                            </div>




                </div>
                <div class="central-card-footer">
                  <div class="central-card-footer-price">
                   <span>${vm.$t("packages-my.small")}</span>
                   <span>$2 000</span>
                  </div>
                    <p>${vm.$t("packages-my.one-user-one")}</p>
                  <div class="central-card-footer-price">
                     <span>${vm.$t("packages-my.medium")}</span>
                    <span>$5 000</span>
                  </div>
                    <p>${vm.$t("packages-my.one-user-multiple")}</p>
                  <div class="central-card-footer-price">
                    <span>${vm.$t("packages-my.large")}</span>
                    <span>$8 000</span>
                  </div>
                    <p>${vm.$t("packages-my.multiple-user-roles")}</p>

                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>


              </div>
              <div class="packages-central-card central-card-two">
               <div class="central-card-header">
                 <h4>${vm.$t("packages-my.pro")}</h4>
                 <p>${vm.$t("packages-my.its-for-you")}</p>
                </div>
                <div class="central-card-main">
                    <ul class="central-card-main-list"/>
                      <li>${vm.$t("packages-my.research-audit")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                <p class="packages-popup-title">${vm.$t("packages-my.research-audit")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.research-text")}</p>
                              </div>
                          </div>
                      </li>

                      <li>${vm.$t("packages-my.user-personas")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.user-personas")}</p>
                                <p class="packages-popup-text">${vm.$t("packages-my.user-personas-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.user-doc")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.user-journey")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.user-journey")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.user-journey-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.user-map")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.initial-user-interview")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.initial-user-interview")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.initial-user-interview-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.definition")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.flow-map")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.flow-map")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.flow-map-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.flow-miro")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.user-stories")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.user-stories")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.user-stories-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.user-storiies-doc")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.wireframes")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.wireframes")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.wireframes-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-mockup")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-mockup")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.ui-mockup-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-styleguide")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-styleguide")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.ui-styleguide-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch-file")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.user-testing")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.user-testing")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.user-testing-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.analysis")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.documentation")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.documentation")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.documentation-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.document")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                    </ul>


                              <div class="packages-popup popup-1">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-mockup")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.ui-mockup-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                              <div class="packages-popup popup-1">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-styleguide")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.ui-styleguide-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch-file")}</span></li>
                              </ul>
                            </div>


                </div>
                <div class="central-card-footer">
                  <div class="central-card-footer-price">
                   <span>${vm.$t("packages-my.small")}</span>
                   <span>$3 500</span>
                  </div>
                    <p>${vm.$t("packages-my.one-user-one")}</p>
                  <div class="central-card-footer-price">
                     <span>${vm.$t("packages-my.medium")}</span>
                     <span>$8 500</span>
                  </div>
                    <p>${vm.$t("packages-my.one-user-multiple")}</p>
                  <div class="central-card-footer-price">
                    <span>${vm.$t("packages-my.large")}</span>
                    <span>$15 000</span>
                  </div>
                    <p>${vm.$t("packages-my.multiple-user-roles")}</p>
                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>
                </div>

              <div class="packages-central-card central-card-three">
               <div class="central-card-header">
                  <h4>${vm.$t("packages-my.custom")}</h4>
                </div>
                <div class="central-card-main">
                <p>${vm.$t("packages-my.if-you-have")}</p>
                </div>
                <div class="central-card-footer">
                    <a href="/get-estimate" class="common-btn">${vm.$t("workshop.contact")}</a>
                </div>
              </div>

          </div>
                `
        },
        {
            id: 2,
            question: "packages-my.website-redesign",
            answer: `
          <div class="packages-top-card-wrapper">
              <div class="packages-top-card card-one">
               <h4>${vm.$t("packages-my.for-whom")}</h4>
               <p>${vm.$t("packages-my.for-buisnesses")}</p>
              </div>
              <div class="packages-top-card card-two">
              <h4>${vm.$t("packages-my.which-kpis")}</h4>
              <p>${vm.$t("packages-my.conversion")}</p>
              </div>
          </div>
            <div class="packages-central-card-wrapper">
               <div class="packages-central-card central-card-one">
                <div class="central-card-header">
                 <h4>${vm.$t("packages-my.basic")}</h4>
                 <p>${vm.$t("packages-my.will-work")}</p>
                </div>
                <div class="central-card-main">
                    <ul class="central-card-main-list">
                      <li class="li-disabled">${vm.$t("packages-my.competitor")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.competitor")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.competitor-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.report")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.analysis-data")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.analysis-data")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.analysis-data-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.report")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.audit")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.audit")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.audit-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.report")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.testing")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.testing")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.testing-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.analysis")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>

                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.flow-map")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.flow-map")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.flow-map-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.flow-miro")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.wireframes")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.wireframes")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.wireframes-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-mockup")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-mockup")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.ui-mockup-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-styleguide")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-styleguide")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.ui-styleguide-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch-file")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li class="li-disabled">${vm.$t("packages-my.user-testing")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.user-testing")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.user-testing-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.analysis")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                  </ul>
                </div>
                <div class="central-card-footer">
                  <div class="central-card-footer-price">
                   <span>${vm.$t("packages-my.small")}</span>
                   <span>$1 500</span>
                  </div>
                    <p>${vm.$t("packages-my.landing")}</p>
                  <div class="central-card-footer-price">
                     <span>${vm.$t("packages-my.medium")}</span>
                    <span>$2 000</span>
                  </div>
                    <p>${vm.$t("packages-my.multipager")}</p>
                  <div class="central-card-footer-price">
                    <span>${vm.$t("packages-my.large")}</span>
                    <span>$3 000</span>
                  </div>
                    <p>${vm.$t("packages-my.big-web")}</p>

                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>
              </div>
              <div class="packages-central-card central-card-two">
               <div class="central-card-header">
                 <h4>${vm.$t("packages-my.pro")}</h4>
                 <p>${vm.$t("packages-my.its-your")}</p>
                </div>
                <div class="central-card-main">
                    <ul class="central-card-main-list"/>
                      <li>${vm.$t("packages-my.competitor")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.competitor")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.competitor-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.report")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.analysis-data")}
                          <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.analysis-data")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.analysis-data-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.report")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.audit")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.audit")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.audit-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.report")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.testing")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.testing")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.testing-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.analysis")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>

                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.flow-map")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                                 <p class="packages-popup-title">${vm.$t("packages-my.flow-map")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.flow-map-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.flow-miro")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.wireframes")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.wireframes")}</p>
                               <p class="packages-popup-text">${vm.$t("packages-my.wireframes-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-mockup")}
                         <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-mockup")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.ui-mockup-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.prototype")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.ui-styleguide")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.ui-styleguide")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.ui-styleguide-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.sketch-file")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                      <li>${vm.$t("packages-my.user-testing")}
                            <div class="packages-img-wrapper">
                              <img class="packages-popup-img" src="/img/images/Question-mark.svg" width="16" height="16" alt="question.png">
                              <img class="packages-popup-img-hover" src="/img/images/Question mark-Hover.svg" width="16" height="16" alt="hover.png">
                              <div class="packages-popup">
                               <p class="packages-popup-title">${vm.$t("packages-my.user-testing")}</p>
                                 <p class="packages-popup-text">${vm.$t("packages-my.user-testing-text")}</p>
                              <p>${vm.$t("packages-my.deliverables")}</p>
                              <ul>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.video-audio")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.analysis")}</span></li>
                              <li><span class="packages-popup-span">${vm.$t("packages-my.todo")}</span></li>
                              </ul>
                            </div>
                          </div>
                      </li>
                    </ul>
                </div>
                <div class="central-card-footer">
                  <div class="central-card-footer-price">
                   <span>${vm.$t("packages-my.small")}</span>
                   <span>$2 500</span>
                  </div>
                    <p>${vm.$t("packages-my.landing")}</p>
                  <div class="central-card-footer-price">
                     <span>${vm.$t("packages-my.medium")}</span>
                     <span>$3 500</span>
                  </div>
                    <p>${vm.$t("packages-my.multipager")}</p>
                  <div class="central-card-footer-price">
                    <span>${vm.$t("packages-my.large")}</span>
                    <span>$5 500</span>
                  </div>
                    <p>${vm.$t("packages-my.big-web")}</p>
                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>
                </div>
              <div class="packages-central-card central-card-three">
               <div class="central-card-header">
                  <h4>${vm.$t("packages-my.custom")}</h4>
                </div>
                <div class="central-card-main">
                <p>${vm.$t("packages-my.if-you-have")}</p>
                </div>
                <div class="central-card-footer">
                    <a href="/get-estimate" class="common-btn">${vm.$t("workshop.contact")}</a>
                </div>
              </div>
          </div>  `
        },
        {
            id: 3,
            question: "packages-my.design-support",
            answer: `
          <div class="packages-top-card-wrapper tab-3">
              <div class="packages-top-card card-one">
                  <h4>${vm.$t("packages-my.for-whom")}</h4>
                   <p>${vm.$t("packages-my.for-teams")}</p>
              </div>
              <div class="packages-top-card card-two tab-3">
                  <h4>${vm.$t("packages-my.which-kpis")}</h4>
                  <span>${vm.$t("packages-my.we-assign")}</span>
              </div>
          </div>
            <div class="packages-central-card-wrapper">
               <div class="packages-central-card central-card-one">
                <div class="central-card-header tab-3">
                 <h4>${vm.$t("packages-my.small")}</h4>
                </div>
                <div class="central-card-main tab-3">
                    <span>${vm.$t("packages-my.up-to")}</span>
                    <span class="white">40</span>
                    <span>${vm.$t("packages-my.hours")}</span>
                    <span class="pink">$2000 / </span>
                    <span class="pink">${vm.$t("packages-my.month")}</span>
                </div>
                <div class="central-card-footer tab-3">
                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>
              </div>
              <div class="packages-central-card central-card-two tab-3">
               <div class="central-card-header tab-3">
                 <h4>${vm.$t("packages-my.medium")}</h4>
                </div>
                <div class="central-card-main tab-3">
                    <span>${vm.$t("packages-my.up-to")}</span>
                    <span class="white">80</span>
                    <span>${vm.$t("packages-my.hours")}</span>
                    <span class="pink">$3500 / </span>
                    <span class="pink">${vm.$t("packages-my.month")}</span>
                </div>
                <div class="central-card-footer tab-3">
                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>
                </div>
              <div class="packages-central-card central-card-three">
               <div class="central-card-header tab-3">
                  <h4>${vm.$t("packages-my.large")}</h4>
                </div>
                <div class="central-card-main tab-3">
                    <span>${vm.$t("packages-my.up-to")}</span>
                    <span class="white">160</span>
                    <span>${vm.$t("packages-my.hours")}</span>
                    <span class="pink">$7000 / </span>
                    <span class="pink">${vm.$t("packages-my.month")}</span>
                </div>
                <div class="central-card-footer">
                    <a href="https://calendly.com/bozkagames/30min?month=2019-05" target="_blank" class="common-btn">${vm.$t("packages-my.request")}</a>
                </div>
              </div>
          </div>  `
        }
    ];
}
