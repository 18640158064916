<template>
	<div class="popup" :class="{ 'accept-cokies': acceptance}">
		<div class="popup-wrapper">
			<div class="popup-inner">
				<div class="popup-content">
					<div class="popup-title">{{$t("popup.title")}}</div>
					<div class="popup-text">
						{{$t("popup.text")}}
					</div>
					<div class="btn-wrapper">
						<button class="common-btn decline-btn">{{$t("popup.decline-btn")}}</button>
						<div class="accept-wrapper">
								<button class="common-btn accept-btn" @click="showContent">{{$t("popup.accept-btn")}}</button>
								<router-link to="/privacy-policy">{{$t("popup.link")}}</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	let vm = {};
	export default {
			name: 'Popup',
			data(){
            return {
							acceptance: false,
							body: ''
            }
				},
		beforeCreate() {
    vm = this;
	},
	mounted() {
		if(!localStorage.bozkacookies) {
			vm.body =  document.querySelector("body");
			vm.body.classList.add('ban-scroll');
		} else {
			vm.acceptance = true;
		}
	},
		methods: {
			showContent: () => {
				vm.acceptance = true;
				vm.body.classList.remove('ban-scroll');
				localStorage.bozkacookies = "bozkacookies";
			}
		}
	}
</script>