<template>
  <main>
    <div class="current-portfolio current-earth_rating">
      <picture>
        <source
          srcset="/img/images/portfolio/Earth_rating/Mobile/Header_img.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Earth_rating/Mobile/Group_7-iphone.png"
          media="(max-width: 414px)"
        />
        <source
          srcset="/img/images/portfolio/Earth_rating/header_img.png"
          media="(max-width: 1699px)"
        />
        <source
          srcset="/img/images/portfolio/Earth_rating/ER_header_full_hd.png"
          media="(min-width: 1700px)"
        />

        <img
          class="main-img_portfolio"
          src="/img/images/portfolio/Earth_rating/ER_header_full_hd.png"
          alt
        />
      </picture>
      <section class="wrapper current-portfolio current-rating-portfolio">
        <h1 class="current-portfolio_title">{{$t("earth-ratings.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("earth-ratings.hash-tag1")}}</li>
          <li>{{$t("earth-ratings.hash-tag2")}}</li>
          <li>{{$t("earth-ratings.hash-tag3")}}</li>
          <li>{{$t("earth-ratings.hash-tag4")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("earth-ratings.name")}}</em>
          {{$t("earth-ratings.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("earth-ratings.description")}}</p>
      </section>
      <picture>
        <source
          srcset="/img/images/portfolio/Earth_rating/Mobile/bg_img_1.png"
          media="(max-width: 500px)"
        />

        <img class="er-main-bg_img" src="/img/images/portfolio/Earth_rating/bg_1.png" alt />
      </picture>
      <div class="wrapper wrapper-earth_rating">
        <div class="portfolio-description rating-target_description">
          <section>
            <h2>{{$t("earth-ratings.target-audience")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("earth-ratings.text1")}}</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Earth_rating/Mobile/target.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Earth_rating/Mobile/Group_8-iphone.png"
              media="(max-width: 414px)"
            />

            <img
              class="rating-audience_img"
              src="/img/images/portfolio/Earth_rating/target_audience.png"
              alt
            />
          </picture>
        </div>

        <div class="portfolio-description rating-challenge_description">
          <section>
            <h2>{{$t("earth-ratings.challenge")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("earth-ratings.text2")}}</p>
            <br />
            <p>1. {{$t("earth-ratings.text3")}}</p>
            <br />
            <p>2. {{$t("earth-ratings.text4")}}</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Earth_rating/Mobile/Challenge.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Earth_rating/Mobile/Group_10-iphone.png"
              media="(max-width: 414px)"
            />

            <img
              class="rating-challenge-img"
              src="/img/images/portfolio/Earth_rating/Challenge.png"
              alt
            />
          </picture>
        </div>

        <div class="portfolio-description rating-process_description">
          <section>
            <h2>{{$t("earth-ratings.process")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              <i18n path="earth-ratings.text5">
                <a
                  target="_blank"
                  href="https://earthratings.com/"
                  place="action"
                >{{$t("earth-ratings.name")}}</a>
              </i18n>
            </p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Earth_rating/Mobile/Process.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Earth_rating/Mobile/Group_9-iphone.png"
              media="(max-width: 414px)"
            />

            <img
              class="rating-process-img"
              src="/img/images/portfolio/Earth_rating/process.png"
              alt
            />
          </picture>
        </div>
      </div>
    </div>

    <section class="more-projects">
      <h2 class="main-title">{{$t("earth-ratings.more-project")}}</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "earth-rating",

  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/health_wizz.png",
          image_hover: "/img/images/portfolio/main/health_wizz_clear.png",
          title: "Health Wizz",
          url: "/portfolio/health",
          tags: [
            {
              name: "#Webapp"
            },
            {
              name: "#Blockchain"
            },
            {
              name: "#Healthcare"
            },
            {
              name: "#Design System"
            },
            {
              name: "#SaaS"
            },
            {
              name: "#Fintech"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Human_capital.png",
          image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
          title: "Human Capital",
          url: "/portfolio/human-capital",
          tags: [
            {
              name: "#Web Site"
            },
            {
              name: "#UX Research"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#Recruitment"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Infra.png",
          image_hover: "/img/images/portfolio/main/Infra_clear.png",
          title: "Infra",
          url: "/portfolio/infra",
          tags: [
            {
              name: "#Redesign"
            },
            {
              name: "#Rebranding"
            },
            {
              name: "#Landing page"
            },
            {
              name: "#Marketing design"
            },
            {
              name: "#SaaS"
            },
            {
              name: "#Cyber Security"
            }
          ]
        }
      ]
    };
  }
};
</script>