<template>
	<div class="container-privacy-policy">
		<div class="wrapper">
			<div class="privacy-policy">
				<div class="policy-section-wrapper">
					<h1>{{$t('privacy-policy.title')}}</h1>
					<p>{{$t("privacy-policy.text1")}}</p>
					<p>{{$t("privacy-policy.text2")}}</p>
				</div>
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section1-title")}}</h2>
					<p>{{$t("privacy-policy.section1-text1")}}</p>

					<p>{{$t("privacy-policy.section1-text2")}}</p>
					<ul>
						<li>{{$t("privacy-policy.list-item1")}}</li>
						<li>{{$t("privacy-policy.list-item2")}}</li>
						<li>{{$t("privacy-policy.list-item3")}}</li>
						<li>{{$t("privacy-policy.list-item4")}}</li>
					</ul>

					<p>{{$t("privacy-policy.section1-text3")}}</p>
					<p>{{$t("privacy-policy.section1-text4")}}</p>	
					<p>{{$t("privacy-policy.section1-text5")}}</p>
					<ul>
						<li>{{$t("privacy-policy.list-item5")}}</li>
						<li>{{$t("privacy-policy.list-item6")}}</li>
						<li>{{$t("privacy-policy.list-item7")}}</li>
						<li>{{$t("privacy-policy.list-item8")}}</li>
						<li>{{$t("privacy-policy.list-item9")}}</li>
						<li>{{$t("privacy-policy.list-item10")}}</li>
					</ul>
				</div>
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section2-title")}}</h2>
					<p>{{$t("privacy-policy.section2-text")}}</p>
				</div>
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section3-title")}}</h2>

						<P>{{$t("privacy-policy.section3-text1")}}</p>
						<p>{{$t("privacy-policy.section3-text2")}}</p>
						<p>{{$t("privacy-policy.section3-text3")}}</p>
				</div>
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section4-title")}}</h2>
					<p>{{$t("privacy-policy.section4-text1")}}</p>
					<p>{{$t("privacy-policysection4-text2.")}}</p>
				</div>		
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section5-title")}}</h2>
					<p>{{$t("privacy-policy.section5-text1")}}</p>
					<p>{{$t("privacy-policy.section5-text2")}}</p>
				</div>
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section6-title")}}</h2>
					<p>{{$t("privacy-policy.section6-text1")}}</p>
				</div>
				<div class="policy-section-wrapper">
					<h2>{{$t("privacy-policy.section7-title")}}</h2>
					<p>{{$t("privacy-policy.section7-text1")}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: "privacy-policy",
  data() {
    return {
      
    };
  }
};
</script>	