<template>
  <div class="faq-item" :key="id">
    <h3 class="faq-item-question">{{$t(question)}}</h3>
    <div class="faq-item-answer" v-html="answer" style="display: none"></div>
  </div>
</template>

<script>
let vm = {};
export default {
  name: "FaqItem",
  props: {
    id: Number,
    question: String,
    answer: String,
    openId: Number,
    makeOpen: Boolean
  },
  data() {
    return {};
  },

  beforeCreate() {
    vm = this;
  },
  mounted() {
  },
  methods: {}
};
</script>
