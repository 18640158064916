<template>
  <div class="wrapper-faq-section wrapper-faq-main">
    <div class="wrap-faq">
      <div class="wrapper">
        <section class="faq-section">
          <h1 class="faq-main-title">Faq</h1>
          <ul class="faq-list">
            <li v-for="item in questions" :key="item.question" @click="toggleOpenPost(item.id)">
              <FaqItemMy
                :id="item.id"
                :question="item.question"
                :answer="item.answer"
                :openId="openId"
                :makeOpen="makeOpen"
              />
            </li>
          </ul>
          <router-link to="/faq" class="faq-main-title">{{$t('showAll')}}</router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import FaqItemMy from "./FaqItemMy";
import { log } from "util";

let vm = {};

export default {
  name: "faq-my",
  components: {
    FaqItemMy
  },
  data() {
    return {      
      makeOpen: true,
      openId: 0,
      lang: "en"
    };
  },
  beforeCreate() {
    vm = this;
  },
  methods: {
    toggleOpenPost(id) {
      console.log("this.$i18n.locale ", this.$i18n.locale);
      vm.$forceUpdate();
      if (vm.openId == id) {
        vm.makeOpen = !vm.makeOpen;
      } else {
        vm.makeOpen = true;
        vm.openId = id;
      }
    }
  },
  computed: {
    questions: () => {
      return [
        {
          id: 1,
          question: "faq.minimum-size",
          answer: `<p>${vm.$t("faq.minimum-size-answer1")}</p>`
        },
        {
          id: 2,
          question: "faq.payment",
          answer: `<p>${vm.$t("faq.payment-answer1")}</p>
          <p>${vm.$t("faq.payment-answer2")}</p>
          <p>${vm.$t("faq.payment-answer3")}</p>
          <p>${vm.$t("faq.payment-answer4")}</p>
          `
        },
        {
          id: 3,
          question: "faq.user-testing",
          answer: `<p>${vm.$t("faq.testing-answer1")}</p>
          <p>${vm.$t("faq.testing-answer2")}</p>
          <p>${vm.$t("faq.testing-answer3")}</p>
          <p>${vm.$t("faq.testing-answer4")}</p>
          <p>${vm.$t("faq.testing-answer5")}</p>
          <div class="example-video"><img src="/img/images/faq/user-testing.png" alt="user testing"/></div>
          <div class="example-video"><img src="/img/images/faq/user-stories.png" alt=""/></div>`
        },
        {
          id: 4,
          question: "faq.preparation1",
          answer: `<p>${vm.$t("faq.preparation-answer1")}</p>
          <p>${vm.$t("faq.preparation-answer1")}</p>
          <p>${vm.$t("faq.preparation-answer2")}</p>
          <p>${vm.$t("faq.preparation-answer3")}</p>
          <p>${vm.$t("faq.preparation-answer4")}</p>
          <p>${vm.$t("faq.preparation-answer5")}</p>
          <p>${vm.$t("faq.preparation-answer6")}</p>`
        } 
      ];
    }
  }
};
</script>

