<template>
  <div class="faq-item" :class="{'active': openId === id && makeOpen}" :key="id">
    <h3 class="faq-item-question">{{$t(question)}}</h3>
    <div v-if="openId === id && makeOpen" class="faq-item-answer" v-html="answer"></div>
  </div>
</template>

<script>
let vm = {};
export default {
  name: "FaqItemMy",
  props: {
    id: Number,
    question: String,
    answer: String,
    openId: Number,
    makeOpen: Boolean
  },
  data() {
    return {};
  },

  beforeCreate() {
    vm = this;
  },
  mounted() {
  },
  methods: {}
};
</script>