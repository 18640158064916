<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/Jobtip/mobile/jobtip_header_img_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Jobtip/mobile/header-iphone.png"
          media="(max-width: 414px)"
        />
        <source srcset="/img/images/portfolio/Jobtip/Header.png" media="(max-width: 1699px)" />
        <source
          srcset="/img/images/portfolio/Jobtip/jobtip_Header-full-hd.png"
          media="(min-width: 1700px)"
        />

        <img
          class="main-img_portfolio main-img_jobtip"
          src="/img/images/portfolio/Jobtip/jobtip_Header-full-hd.png"
          alt
        />
      </picture>

      <section class="wrapper current-portfolio current-jobtip">
        <h1 class="current-portfolio_title">{{$t("jobtip.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("jobtip.hash-tag1")}}</li>
          <li>{{$t("jobtip.hash-tag2")}}</li>
          <li>{{$t("jobtip.hash-tag3")}}</li>
          <li>{{$t("jobtip.hash-tag4")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("jobtip.name")}}</em>
          {{$t("jobtip.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("jobtip.description")}}</p>
      </section>
      <picture>
        <source
          srcset="/img/images/portfolio/Jobtip/mobile/divider_1_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Jobtip/mobile/employer_branding_jobtip_iphone.png"
          media="(max-width: 414px)"
        />

        <img class="jobtip-divider" src="/img/images/portfolio/Jobtip/divider_1.png" alt />
      </picture>

      <div class="wrapper wrapper-jobtip">
        <div class="portfolio-description task-description">
          <section>
            <h2>Task</h2>
            <div class="horizontal-line"></div>
            <p>
              <i18n path="jobtip.text1">
                <a target="_blank" href="https://jobtip.com/" place="{action}">{{$t("jobtip.name")}}</a>
              </i18n>
            </p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Jobtip/mobile/jobtip_tasks.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Jobtip/mobile/Imac2-iphone.png"
              media="(max-width: 414px)"
            />

            <img class="jobtip-task_img" src="/img/images/portfolio/Jobtip/task.png" alt />
          </picture>
        </div>
      </div>

      <picture>
        <source
          srcset="/img/images/portfolio/Jobtip/mobile/divider_2_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Jobtip/mobile/job_application_iphone.png"
          media="(max-width: 414px)"
        />

        <img
          class="jobtip-divider jobtip-divider-2"
          src="/img/images/portfolio/Jobtip/Divider_2.png"
          alt
        />
      </picture>

      <div class="wrapper wrapper-jobtip">
        <div class="portfolio-description procces-description">
          <section>
            <h2>{{$t("jobtip.process")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("jobtip.text2")}}</p>
            <p>{{$t("jobtip.text3")}}</p>
            <p>{{$t("jobtip.text4")}}</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Jobtip/mobile/process.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Jobtip/mobile/process-iphooone.png"
              media="(max-width: 414px)"
            />

            <img class="jobtip-procces_img" src="/img/images/portfolio/Jobtip/Process.png" alt />
          </picture>
        </div>
      </div>
    </div>

    <section class="more-projects">
      <h2 class="main-title">More projects</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "jobtip",
  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Human_capital.png",
          image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
          title: "Human Capital",
          url: "/portfolio/human-capital",
          tags: [
            {
              name: "#Web Site"
            },
            {
              name: "#UX Research"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#Recruitment"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Infra.png",
          image_hover: "/img/images/portfolio/main/Infra_clear.png",
          title: "Infra",
          url: "/portfolio/infra",
          tags: [
            {
              name: "#Redesign"
            },
            {
              name: "#Rebranding"
            },
            {
              name: "#Landing page"
            },
            {
              name: "#Marketing design"
            },
            {
              name: "#SaaS"
            },
            {
              name: "#Cyber Security"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Earth_rating.png",
          image_hover: "/img/images/portfolio/main/Earth_rating_clear.png",
          title: "Earth Rating",
          url: "/portfolio/rating",
          tags: [
            {
              name: "#Web App Prototyping"
            },
            {
              name: "#Presentation"
            },
            {
              name: "#Ecommerce"
            },
            {
              name: "#Environment"
            }
          ]
        }
      ]
    };
  }
};
</script>