<template>
  <div class="wrapper-packages-section">
    <div class="wrap-packages">
      <div class="wrapper">
        <section class="packages-my">
          <h2>{{$t("packages-my.packages-title")}}</h2>
          <h3 class="packages-subtitle">{{$t("packages-my.subtitle")}}</h3>
          <ul class="packages-list">
            <li v-for="item in packages" :key="item.question">
              <PackagesItem
                :id="item.id"
                :question="item.question"
                :answer="item.answer"
                :togglePost="toggleOpenPost"
                :makeOpen="makeOpen"
                :openId="openId"
              />
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import PackagesItem from "./PackagesItem";
import { listPackages } from "./list";

let vm = {};

export default {
  name: "packages-my",
  components: {
    PackagesItem
  },
  beforeCreate() {
    vm = this;
  },
    data() {
      return {
          packages: listPackages(vm),
          makeOpen: vm.makeOpen,
          openId: vm.openId,
      }
    },
    mounted() {
        $('.packages-item .packages-item-question').on('click', function () {
            let minusHeight = window.innerWidth > 764 ? 140 : 80;
            const activeClass = 'active';
            $([document.documentElement, document.body]).animate({
                scrollTop: $(this).offset().top - minusHeight
            }, 400);
            $('.central-card-header').on('click', function (event) {
                const that = this;
                event.stopPropagation();
                $('.central-card-header').not(that).removeClass(activeClass)
                if (window.innerWidth < 792) {
                    $('.central-card-main').css('display', 'none');
                    $('.central-card-footer').css('display', 'none');
                    if (!$(that).hasClass(activeClass)) {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(this).offset().top - minusHeight
                        }, {
                            duration: 400,
                            complete: function() {
                                $(that).next().show(300);
                                $(that).next().next().show(100);
                             }
                        });
                    }
                    $(that).toggleClass(activeClass);
                }
            })
        })
    },
    methods: {
        toggleOpenPost: (id) => {
            vm.makeOpen = vm.openId !== id || !vm.makeOpen && vm.openId === id;
            vm.openId = id;
        },
    }
};

</script>

