<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_header_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_header_mobile-iphone.png"
          media="(max-width: 414px)"
        />
        <source
          srcset="/img/images/portfolio/Stress_guide/Stress_guide_header_img.png"
          media="(max-width: 1699px)"
        />
        <source
          srcset="/img/images/portfolio/Stress_guide/Stress_guide_header_img-full-hd.png"
          media="(min-width: 1700px)"
        />

        <img
          class="main-img_portfolio main-stress_guide"
          src="/img/images/portfolio/Stress_guide/Stress_guide_header_img-full-hd.png"
          alt
        />
      </picture>
      <section class="wrapper current-portfolio current-portfolio_guide">
        <h1 class="current-portfolio_title">{{$t("stress-guide.name")}}</h1>
        <ul class="portfolio-list_tags">
          <li>{{$t("stress-guide.hash-tag1")}}</li>
          <li>{{$t("stress-guide.hash-tag2")}}</li>
          <li>{{$t("stress-guide.hash-tag3")}}</li>
          <li>{{$t("stress-guide.hash-tag4")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("stress-guide.name")}}</em>
          {{$t("stress-guide.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("stress-guide.description")}}</p>
      </section>

      <div class="wrapper wrapper-stress_guide">
        <div class="portfolio-description portfolio-story portfolio-basics">
          <section>
            <h2>{{$t("stress-guide.basics")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              <i18n path="stress-guide.text1">
                <a
                  target="_blank"
                  href="https://www.kenkou.de/"
                  place="action"
                >{{$t("stress-guide.name")}}</a>
              </i18n>
            </p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_Basics_mobile.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_Basics_mobile-iphone.png"
              media="(max-width: 414px)"
            />

            <img
              class="img-basis"
              src="/img/images/portfolio/Stress_guide/Stress_guide_Basics.png"
              alt
            />
          </picture>
        </div>

        <div class="portfolio-description portfolio-process">
          <section>
            <h2>{{$t("stress-guide.android-app")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("stress-guide.text2")}}</p>
          </section>
        </div>
      </div>

      <picture>
        <source
          srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_middle_img_mobile.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_middle_img_mobile-iphone.png"
          media="(max-width: 414px)"
        />

        <img
          class="middle-stress_guide"
          src="/img/images/portfolio/Stress_guide/Stress_guide_middle_img.png"
          alt
        />
      </picture>

      <div class="wrapper">
        <div class="portfolio-description portfolio-story portfolio-basics full-app_redesign">
          <section>
            <h2>{{$t("stress-guide.redesign")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("stress-guide.text3")}}</p>
            <br />
            <p>{{$t("stress-guide.text4")}}</p>
            <br />
            <p>{{$t("stress-guide.text5")}}</p>
            <picture>
              <source
                srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_app_design_mobile.png"
                media="(max-width: 320px)"
              />
              <source
                srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_app_design_mobile-iphone.png"
                media="(max-width: 414px)"
              />

              <img
                class="bottom-img_stress-guide"
                src="/img/images/portfolio/Stress_guide/Stress_guide_app_design.png"
                alt
              />
            </picture>
          </section>
        </div>
      </div>
    </div>

    <section class="more-projects">
      <h2 class="main-title">{{$t("stress-guide.more-projects")}}</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "stress-guide",
  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Alacris.png",
          image_hover: "/img/images/portfolio/main/Alacris_clear.png",
          title: " Alacris",
          url: "/portfolio/alacris",
          tags: [
            {
              name: "#Design Support"
            },
            {
              name: "#Demo Prototype"
            },
            {
              name: "#Fintech"
            },
            {
              name: "#Blockchain"
            },
            {
              name: "#Landing Page"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Jobtip.png",
          image_hover: "/img/images/portfolio/main/Jobtip_clear.png",
          title: "Jobtip",
          url: "/portfolio/jobtip",
          tags: [
            {
              name: "#Redesign"
            },
            {
              name: "#Landing Page"
            },
            {
              name: "#Rebranding"
            },
            {
              name: "#Illustrations"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/health_wizz.png",
          image_hover: "/img/images/portfolio/main/health_wizz_clear.png",
          title: "Health Wizz",
          url: "/portfolio/health",
          tags: [
            {
              name: "#Webapp"
            },
            {
              name: "#Blockchain"
            },
            {
              name: "#Healthcare"
            },
            {
              name: "#Design System"
            },
            {
              name: "#SaaS"
            },
            {
              name: "#Fintech"
            }
          ]
        }
      ]
    };
  }
};
</script>