<template>
    <!--SECTION PORTFOLIO-->
    <div id="portfolio" class="section-portfolio">
        <div class="wrapper">
            <section class="container-portfolio">
                <h2 class="main-title">{{ $t("section.portfolio") }}</h2>
                <MoreProjects :projects="moreProjects" />
            </section>
            <section class="more-clients">
                <h2 class="main-title">{{ $t("section.moreClients") }}</h2>
                <ul class="list-more-clients row container">
                    <li>
                        <img src="/img/images/more_clients/Leaf_labs.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/Space.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/Jobtip.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/Plebian.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/legicash.png" alt />
                    </li>
                    <li class="europ">
                        <img src="/img/images/more_clients/Europe.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/kenkou.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/At_T.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/verizon.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/Columbia.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/MIT.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/Figur%208.png" alt />
                    </li>
                    <li>
                        <img src="/img/images/more_clients/Ember.png" alt />
                    </li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";

export default {
    name: "portfolio-my",
    components: { MoreProjects },
    data() {
        return {
            moreProjects: [
                {
                    image: "img/images/portfolio/main/Ember_clear_big.png",
                    image_hover: "img/images/portfolio/main/Ember_big.png",
                    title: "embr.name",
                    url: "portfolio/ember",
                    tags: [
                        {
                            name: "embr.hash-tag1"
                        },
                        {
                            name: "embr.hash-tag2"
                        },
                        {
                            name: "embr.hash-tag3"
                        },
                        {
                            name: "embr.hash-tag4"
                        },
                        {
                            name: "embr.hash-tag5"
                        }
                    ]
                },
                {
                    image:
                        "img/images/portfolio/main/Stressguide_clear_big.png",
                    image_hover:
                        "img/images/portfolio/main/Stressguide_big.png",
                    title: "stress-guide.name",
                    url: "portfolio/stress-guide",
                    tags: [
                        {
                            name: "stress-guide.hash-tag1"
                        },
                        {
                            name: "stress-guide.hash-tag2"
                        },
                        {
                            name: "stress-guide.hash-tag3"
                        },
                        {
                            name: "stress-guide.hash-tag4"
                        }
                    ]
                },
                {
                    image: "img/images/portfolio/main/Alacris_clear_big.png",
                    image_hover: "img/images/portfolio/main/Alacris_big.png",
                    title: "alacris.name",
                    url: "portfolio/alacris",
                    tags: [
                        {
                            name: "alacris.hash-tag1"
                        },
                        {
                            name: "alacris.hash-tag2"
                        },
                        {
                            name: "alacris.hash-tag3"
                        },
                        {
                            name: "alacris.hash-tag4"
                        },
                        {
                            name: "alacris.hash-tag5"
                        }
                    ]
                },
                {
                    image: "img/images/portfolio/main/Jobtip_clear_big.jpg",
                    image_hover: "img/images/portfolio/main/Jobtip_big.png",
                    title: "jobtip.name",
                    url: "portfolio/jobtip",
                    tags: [
                        {
                            name: "jobtip.hash-tag1"
                        },
                        {
                            name: "jobtip.hash-tag2"
                        },
                        {
                            name: "jobtip.hash-tag3"
                        },
                        {
                            name: "jobtip.hash-tag4"
                        }
                    ]
                },
                {
                    image:
                        "img/images/portfolio/main/health_wizz_clear_big.png",
                    image_hover:
                        "img/images/portfolio/main/health_wizz_big.png",
                    title: "health-wizz.name",
                    url: "portfolio/health",
                    tags: [
                        {
                            name: "health-wizz.hash-tag1"
                        },
                        {
                            name: "health-wizz.hash-tag2"
                        },
                        {
                            name: "health-wizz.hash-tag3"
                        },
                        {
                            name: "health-wizz.hash-tag4"
                        },
                        {
                            name: "health-wizz.hash-tag5"
                        },
                        {
                            name: "health-wizz.hash-tag6"
                        }
                    ]
                },
                {
                    image:
                        "img/images/portfolio/main/Human_capital_clear_big.png",
                    image_hover:
                        "img/images/portfolio/main/Human_capital_big.png",
                    title: "human-capital.name",
                    url: "portfolio/human-capital",
                    tags: [
                        {
                            name: "human-capital.hash-tag1"
                        },
                        {
                            name: "human-capital.hash-tag2"
                        },
                        {
                            name: "human-capital.hash-tag3"
                        },
                        {
                            name: "human-capital.hash-tag4"
                        }
                    ]
                },
                {
                    image: "img/images/portfolio/main/Infra_clear_big.png",
                    image_hover: "img/images/portfolio/main/Infra_big.png",
                    title: "infra.name",
                    url: "portfolio/infra",
                    tags: [
                        {
                            name: "infra.hash-tag1"
                        },
                        {
                            name: "infra.hash-tag2"
                        },
                        {
                            name: "infra.hash-tag3"
                        },
                        {
                            name: "infra.hash-tag4"
                        },
                        {
                            name: "infra.hash-tag5"
                        },
                        {
                            name: "infra.hash-tag6"
                        }
                    ]
                },
                {
                    image:
                        "img/images/portfolio/main/Earth_rating_clear_big.png",
                    image_hover:
                        "img/images/portfolio/main/Earth_rating_big.png",
                    title: "earth-ratings.name",
                    url: "portfolio/rating",
                    tags: [
                        {
                            name: "earth-ratings.hash-tag1"
                        },
                        {
                            name: "earth-ratings.hash-tag2"
                        },
                        {
                            name: "earth-ratings.hash-tag3"
                        },
                        {
                            name: "earth-ratings.hash-tag4"
                        }
                    ]
                }
            ]
        };
    }
};
</script>
