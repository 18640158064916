<template>
    <main>
        <MainSection />
        <Roadmap/>
        <Industries />
        <PackagesPage />
        <Clients />
        <!-- <Portfolio /> -->
        <SectionClientsMy />
        <Workshop />
        <Team />
        <FaqPageMy />
        <Popup />
    </main>
</template>

<script>
    import Roadmap from './Roadmap';
    import MainSection from './MainSection';
    import Workshop from './Workshop';
    import Clients from './Clients';
    import Portfolio from './SectionPortfolio';
    import Industries from './Industries';
    import Team from './Team';
    import Popup from './Popup';
    import SectionClientsMy from './SectionClientsMy';
    // import FaqPagePackages from '../FrequentlyAskedQuestions/FaqPage';
    import PackagesPage from '../Packages/PackagesPage';
    import FaqPageMy from '../FrequentlyAskedQuestions/FaqPageMy';


    export default {
        name: 'home',
        components: {
            Roadmap,
            MainSection,
            Workshop,
            Clients,
            Portfolio,
            Industries,
            Team,
            Popup,
            SectionClientsMy,
            PackagesPage,
            FaqPageMy
        }
    }
</script>