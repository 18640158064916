<template>
  <header id="header" v-scroll="handleScroll">
    <nav class="main-nav wrapper row">
        <div class="wrap-burger-menu">
            <div id="nav-icon4" v-on:click="toggleDesktopMenu()" :class="[activeBurger ? 'open' : '']">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
      <div class="wrap-desktop-list-menu" :class="[activeBurger ? 'active-desktop-list-menu' : '']">
        <ul id="nav" class="list-main-nav">
            <li>
                <a class="menu-item">
                    <div class="wrap-burger-menu">
                        <div id="nav-icon4" v-on:click="toggleDesktopMenu()" :class="[activeBurger ? 'open' : '']">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </a>
            </li>
          <li v-for="item in HeaderMenuAnchor" :key="item.name" @click="activeBurger = false">
              <router-link :to="'/' + item.href" class="menu-item">{{$t(`${item.translate}`)}}</router-link>
          </li>
          <li v-for="item in HeaderMenu" :key="item.name" @click="activeBurger = false">
            <router-link :to="item.href" class="menu-item">{{$t(`${item.translate}`)}}</router-link>
          </li>
        </ul>
      </div>
      <a href="/" class="logo logo-desktop">
        <img src="/img/images/logo/logo.svg" alt />
      </a>
      <div class="header-right-wrapper">
        <LanguageSwitcher />
        <ListSocial class="list-social" />
      </div>
      <div class="wrap-mobile-menu">
          <div
              id="nav-icon4"
              v-on:click="activeBurgerMobile = !activeBurgerMobile"
              :class="[activeBurgerMobile ? 'open' : '']"
          >
              <span></span>
              <span></span>
              <span></span>
          </div>
        <router-link to="/" class="logo">
          <img src="/img/images/logo/logo.svg" alt />
        </router-link>
        <div
          class="wrap-mobile-list-menu"
          :class="[activeBurgerMobile ? 'active-mobile-list-menu' : '']"
        >
          <ul class="mobile-list-menu">
            <li v-on:click="activeBurgerMobile = false">
              <router-link to="/" class="logo">
                <img src="/img/images/logo/logo.svg" alt />
              </router-link>
            </li>
            <li v-for="item in HeaderMenuAnchor" v-on:click="activeBurgerMobile = false" :key="item.name">
              <router-link :to="'/' + item.href">{{$t(`${item.translate}`)}}</router-link>
            </li>
            <li v-for="item in HeaderMenu" v-on:click="activeBurgerMobile = false" :key="item.name">
              <router-link :to="item.href">
                {{$t(`${item.translate}`)}}
              </router-link>
            </li>
          </ul>
          <LanguageSwitcher />
          <!-- <div class="locale-menu row col-md-1">
                    <a href="#" @click="setLocale('en')" class="active en"
                        >En</a
                    >&nbsp;|&nbsp;
                    <a href="#" @click="setLocale('de')" class="de">De</a>
          </div>-->
          <ListSocial class="list-social list-social-mobile row col-xs-7" />
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import Vue from "vue";
import ListSocial from "./ListSocial";
import { HeaderMenu, HeaderMenuAnchor } from "../constants/consts";
import LanguageSwitcher from "./LanguageSwitcher";

let vm = {};

Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

export default {
  name: "app-header",
  components: { ListSocial, LanguageSwitcher },

  data() {
    return {
      activeBurger: false,
      activeBurgerMobile: false,
      HeaderMenu,
      HeaderMenuAnchor
    };
  },
  mounted() {
    vm.headerPortfolio();
    const burger = document.querySelector(".wrap-burger-menu");
    const paddingMenu = burger.getBoundingClientRect().left;
    const burgerMenu = Array.from(
      document.querySelectorAll(".wrap-desktop-list-menu .menu-item")
    );
    burgerMenu.forEach(item => {
      item.style.paddingLeft = paddingMenu + "px";
    });

    const isClutch = window.location.href.indexOf("clutch") != -1;
    if (isClutch) {
      vm.setHeaderColor();
    }
  },
  beforeCreate() {
    vm = this;
  },

  computed: {
    linesActive: () => (vm.activeService ? "active" : ""),
    mobileMenu: () => HeaderMenu.filter(item => item.mobile)
  },

  methods: {
    goToServices: serviceUrl => {
      vm.$refs.submenu.classList.remove("open-submenu");
      vm.$router.push({
        name: "services-page",
        params: { Surl: serviceUrl }
      });
    },
    toggleDesktopMenu: () => {
      vm.activeBurger = !vm.activeBurger;
      if (!vm.activeBurger && vm.$refs.submenu) {
        vm.$refs.submenu.classList.remove("open-submenu");
      }
    },
    setHeaderColor() {
      document.getElementById("header").setAttribute("style", "background-color: #25273E;");
    },
    closeDesktopMenu: event => {
      event.stopPropagation();
      vm.activeBurger = false;
      if (vm.$refs.submenu) {
          vm.$refs.submenu.classList.remove("open-submenu");
      }
    },

    dropDown: event => {
      let panel = event.currentTarget.nextElementSibling;
      if (panel.style.height) {
        panel.style.height = null;
        panel.classList.remove("active-list-dropdown");
      } else {
        panel.style.height = panel.scrollHeight + "px";
        panel.classList.add("active-list-dropdown");
      }
    },

    dropdownDesktop: e => {
      let panel = e.currentTarget.nextElementSibling;
      panel.classList.toggle("open-submenu");
    },

    headerPortfolio: () => {
      let currentUrl = window.location.pathname.slice(1, 10);
      let currentWork = window.location.pathname.slice(1);
      if (
        currentWork === "how-we-work" ||
        currentUrl === "portfolio" ||
        currentWork === "faq" ||
        currentWork.includes("post/")
      ) {
        let header = document.getElementById("header");
        header.style.backgroundColor = "#25273E";
      }
    },

    handleScroll: (evt, el) => {
      if (window.scrollY > 230) {
        el.setAttribute("style", "background-color: #25273E;");
      } else {
        let currentUrl = window.location.pathname.slice(1, 10);
        let currentWork = window.location.pathname.slice(1);
        if (
          currentUrl === "portfolio" ||
          currentWork === "how-we-work" ||
          currentUrl === "services/" ||
          currentWork === "privacy-policy" ||
          currentWork === "blog" ||
          currentWork === "get-estimate" ||
          currentWork === "faq" ||
          currentWork === "clutch" ||
          currentWork.includes("post/")
        ) {
          el.setAttribute("style", "background-color: #25273E;");
        } else {
          el.setAttribute("style", "background-color: transparent;");
        }
      }
    }
    // setLocale: locale => {
    //   import(`../../locale/${locale}.json`).then(msgs => {
    //     vm.$i18n.setLocaleMessage(locale, msgs);
    //     vm.$i18n.locale = locale;
    //   });
    // }
  }
};
</script>
