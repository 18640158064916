import i18n from "../../plugins/i18n";

export const CLIENTS = [
    {
        image:
            "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/David.png",
        description: "clients.embr.description",
        link: "https://www.linkedin.com/in/dctanugi/",
        name: "clients.embr.name",
        project: "Embr Labs",
        project_link: "https://embrlabs.com/"
    },
    {
        image:
            "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Christopher.png",
        description: "clients.alacris.description",
        link: "https://www.linkedin.com/in/chrisswenor/",
        name: "clients.alacris.name",
        project: "Alacris",
        project_link: "https://alacris.io/"
    },
    {
        image:
            "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Raj.png",
        description: "clients.healthwizz.description",
        link: "https://www.linkedin.com/in/rajsharmar/",
        name: "clients.healthwizz.name",
        project: "Healthwizz, LLC",
        project_link: "https://www.healthwizz.com/"
    }
];

export const ROADMAP = [
    {
    image: "./constants/image/Group-16.svg",
    new_subtitle: "roadmap.roadmap-new_subtitle",
    bottom_subtitle: "roadmap.roadmap-bottom_subtitle"
}
];

export const INDUSTRIES = [
    {
        // image: "../constants/image/Helth.png",
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Industries/Helth.png",
        title: "industries.healthcare-title",
        subtitle: "industries.healthcare-subtitle",
        new_subtitle: "industries.healthcare-new_subtitle"
    },
    {
        image:
            "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Industries/blockchain.png",
        title: "industries.fintech-title",
        subtitle: "industries.fintech-subtitle",
        new_subtitle: "industries.fintech-new_subtitle"
    },
    {
        image:
            "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Industries/Iot.png",
        title: "industries.ioT-title",
        subtitle: "industries.ioT-subtitle",
        new_subtitle: "industries.ioT-new_subtitle"
    }
];

export const HeaderMenuAnchor = [
    {
        name: "Portfolio",
        href: "portfolio-my",
        translate: "header-menu.portfolio"
    },
    // {
    //     name: "Portfolio",
    //     href: "#portfolio",
    //     translate: "header-menu.portfolio"
    // },
    /*
    {
        name: "Industries",
        href: "#industries",
        translate: "header-menu.industries"
    },
    */
    {
        name: "Our team",
        href: "#team",
        translate: "header-menu.team"
    },
    
];
export const HeaderMenu = [
    {
        name: "How we work",
        href: "/how-we-work",
        translate: "header-menu.how-we-work"
    },
    {
        name: "FAQ",
        href: "/faq",
        translate: "header-menu.faq"
    }
];

export const FooterMenu = [
    {
        name: "How we work",
        href: "/how-we-work",
        translate: "footer-menu.how-we-work"
    },
    {
        name: "FAQ",
        href: "/faq",
        translate: "footer-menu.faq"
    },
    {
        name: "Privacy Policy",
        href: "/privacy-policy",
        translate: "footer-menu.policy"
    }
];
