import axios from 'axios'

const requestGet = (url, params) => {
    return axios.get(url, { params })
        .then((response) => { return { status: true, data: response.data } })
        .catch((error) => { return { status: false, error } });
};

const requestPost = (url, body) => {
    return axios.post(url, body)
        .then(response => { return { status: body } })
        .catch(error => { return { status: body } });
};

export { requestGet, requestPost };