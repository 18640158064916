<template>
  <main>
    <div class="current-portfolio">
      <picture>
        <source
          srcset="/img/images/portfolio/ember/Mobile/Ember_header-iphone.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/ember/Mobile/Ember_header.png"
          media="(max-width: 414px)"
        />
        <source srcset="/img/images/portfolio/ember/Ember_header.png" media="(max-width: 1699px)" />
        <source
          srcset="/img/images/portfolio/ember/Ember_header-full-hd.png"
          media="(min-width: 1700px)"
        />
        <img
          class="main-img_portfolio main-img_ember"
          src="/img/images/portfolio/ember/Ember_header-full-hd.png"
          alt
        />
      </picture>
      <section class="wrapper current-portfolio current-ember">
        <h1 class="current-portfolio_title">{{$t("embr.name")}}</h1>
        <ul class="portfolio-list_tags ember-list_tags">
          <li>{{$t("embr.hash-tag1")}}</li>
          <li>{{$t("embr.hash-tag2")}}</li>
          <li>{{$t("embr.hash-tag3")}}</li>
          <li>{{$t("embr.hash-tag4")}}</li>
          <li>{{$t("embr.hash-tag5")}}</li>
        </ul>
        <h2 class="current-portfolio_subtitle">
          <em>{{$t("embr.name")}}</em>
          {{$t("embr.subtitle")}}
        </h2>
        <p class="current-portfolio_description">{{$t("embr.description")}}</p>
      </section>
      <picture>
        <source
          srcset="/img/images/portfolio/ember/Mobile/wave-pattern.png"
          media="(max-width: 320px)"
        />
        <source
          srcset="/img/images/portfolio/ember/Mobile/wave-pattern-iphone.png"
          media="(max-width: 414px)"
        />

        <img class="ember-wave" src="/img/images/portfolio/ember/wave-pattern.png" alt />
      </picture>
      <div class="wrapper">
        <div class="portfolio-description portfolio-story">
          <section>
            <h2>{{$t("embr.story")}}</h2>
            <div class="horizontal-line"></div>
            <p>
              <i18n path="embr.collaboration">
                <a
                  target="_blank"
                  href="https://embrlabs.com/"
                  place="action"
                >{{$t("embr.linkTxt")}}</a>
              </i18n>
            </p>
            <br />
            <p>({{$t("embr.story-txt1")}})</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/ember/Mobile/Story.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/ember/Mobile/Story-iphone.png"
              media="(max-width: 414px)"
            />

            <img class="ember-story_img" src="/img/images/portfolio/ember/Story.png" alt />
          </picture>
        </div>

        <div class="portfolio-description portfolio-process">
          <section>
            <h2>{{$t("embr.process")}}</h2>
            <div class="horizontal-line"></div>
            <p>{{$t("embr.process-txt1")}}</p>
            <br />
            <p>{{$t("embr.process-txt2")}}</p>
            <br />
            <p>{{$t("embr.process-txt3")}}</p>
          </section>
          <picture>
            <source
              srcset="/img/images/portfolio/ember/Mobile/Process_1.png"
              media="(max-width: 320px)"
            />
            <source
              srcset="/img/images/portfolio/ember/Mobile/Rectangle-iphone.png"
              media="(max-width: 414px)"
            />

            <img class="process-img" src="/img/images/portfolio/ember/Process.png" alt />
          </picture>
        </div>
        <picture>
          <source
            srcset="/img/images/portfolio/ember/Mobile/Process_2.png"
            media="(max-width: 320px)"
          />
          <source
            srcset="/img/images/portfolio/ember/Mobile/screens_embr-iphone.png"
            media="(max-width: 414px)"
          />

          <img class="portfolio-bottom_img" src="/img/images/portfolio/ember/process_2.png" alt />
        </picture>
      </div>
    </div>

    <img class="ember-projects_bg" src="/img/images/portfolio/ember/bottom_black_bg.png" alt />
    <section class="more-projects ember-more_projects">
      <h2 class="main-title">{{$t("embr.more-projects")}}</h2>
      <div class="wrapper">
        <MoreProjects :projects="moreProjects" />
      </div>
    </section>
    <Workshop />
  </main>
</template>

<script>
import MoreProjects from "../partials/MoreProjects";
import Workshop from "../Home/Workshop";

export default {
  name: "ember",
  components: { MoreProjects, Workshop },
  data() {
    return {
      moreProjects: [
        {
          image: "/img/images/portfolio/main/Stressguide.png",
          image_hover: "/img/images/portfolio/main/Stressguide_clear.png",
          title: " Stress Guide",
          url: "/portfolio/stress-guide",
          tags: [
            {
              name: "#Mobile App"
            },
            {
              name: "#Redesign"
            },
            {
              name: "#User Research"
            },
            {
              name: "#Healthcare"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Alacris.png",
          image_hover: "/img/images/portfolio/main/Alacris_clear.png",
          title: " Alacris",
          url: "/portfolio/alacris",
          tags: [
            {
              name: "#Design Support"
            },
            {
              name: "#Demo Prototype"
            },
            {
              name: "#Fintech"
            },
            {
              name: "#Blockchain"
            },
            {
              name: "#Landing Page"
            }
          ]
        },
        {
          image: "/img/images/portfolio/main/Jobtip.png",
          image_hover: "/img/images/portfolio/main/Jobtip_clear.png",
          title: "Jobtip",
          url: "/portfolio/jobtip",
          tags: [
            {
              name: "#Redesign"
            },
            {
              name: "#Landing Page"
            },
            {
              name: "#Rebranding"
            },
            {
              name: "#Illustrations"
            }
          ]
        }
      ]
    };
  }
};
</script>