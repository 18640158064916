<template>
  <div class="packages-item" :class="{'active': isOpen(id)}" :key="id">
    <h3 @click="togglePost(id)" class="packages-item-question">{{$t(question)}}</h3>
    <div v-if="isOpen(id)" class="packages-item-answer" v-html="answer"></div>
  </div>
</template>

<script>
let vm = {};
export default {
  name: "PackagesItem",
  props: {
    id: Number,
    question: String,
    answer: String,
    makeOpen: Boolean,
    openId: Number,
    togglePost: Function,
  },
  beforeCreate() {
    vm = this;
  },
  methods: {
    isOpen: (id) => {
      return vm.$props.openId === id && vm.$props.makeOpen;
    },
  },
};
</script>
