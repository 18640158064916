<template>
  <div>
    <div class="wrap-how-we-work">
      <div class="wrapper">
        <h2 class="caption-work">{{$t("work.title")}}</h2>
        <div class="wrap-animation_work wrap-sharp">
          <section>
            <h3 class="title-work">{{$t("work.sharp.title-work")}}</h3>
            <p class="description-work">{{$t("work.sharp.description-work")}}</p>
          </section>
          <img width="203" height="203" src="/img/images/gif/Cut_full.gif" alt />
        </div>
        <div class="wrap-animation_work wrap-flexible">
          <section>
            <h3 class="title-work">{{$t("work.flexible.title-work")}}</h3>
            <p class="description-work">{{$t("work.flexible.description-work")}}</p>
          </section>
          <img width="203" height="203" src="/img/images/gif/Flexible_full.gif" alt />
        </div>
        <div class="wrap-animation_work wrap-human_work">
          <section>
            <h3 class="title-work">{{$t("work.human.title-work")}}</h3>
            <p class="description-work">{{$t("work.human.description-work")}}</p>
          </section>
          <img width="203" height="203" src="/img/images/gif/Heart_full.gif" alt />
        </div>
        <div class="how-work-main-text">
          <p>{{$t("work.main-text-part1")}} <router-link to="/#team">{{$t("work.link")}}</router-link> {{$t("work.main-text-part2")}}</p>
          <p>{{$t("work.main-text-part3")}}</p>
        </div>
      </div>
    </div>
    <Workshop />
  </div>
</template>

<script>
import Workshop from "./Workshop";
export default {
  name: "how-we-work",
  components: {
    Workshop
  }
};
</script>