<template>
  <div class="locale-menu row">
    <a href="#" @click="event => updateLocale('en', event)" :class="[isActive ? 'active en' : 'en' ]">En</a>&nbsp;|&nbsp;
    <a href="#" @click="event => updateLocale('de', event)" :class="[!isActive ? 'active de' : 'de' ]">De</a>
  </div>
</template>

<script>
let vm = {};

let url = window.location.hostname.split('.');
let domain = url.pop();

let activeLanguage = (domain === 'com') ? 'en' : 'de';

export default {
  name: "LanguageSwitcher",
  data: () => {
    return {
      isActive: true
    };
  },
  mounted() {
    this.updateLocale(activeLanguage);
  },
  beforeCreate() {
    vm = this;
  },
  methods: {
    updateLocale: function(locale, event = null) {
      event !== null ? event.preventDefault() : '';
      this.isActive = locale === "de" ? false : true;
      this.importLocale(locale);
    },
    importLocale: locale => {
      import(`../../locale/${locale}.json`).then(msgs => {
        vm.$i18n.setLocaleMessage(locale, msgs);
        vm.$i18n.locale = locale;
      });
    }
  }
};
</script>
