<template>
  <div class="successfully-send">
    <img src="/img/images/other/check.svg" alt />
    <h2>
      {{$t("successfully1")}}
      <br />{{$t("successfully2")}}
    </h2>
    <a href="/" class="link-to-main-page">{{$t("successfully-ok")}}</a>
  </div>
</template>

<script>
export default {
  name: "successfully-send",

  data() {
    return {};
  }
};
</script>