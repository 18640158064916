<template>
  <main id="clutch">
    <section class="wrapper current-clutch">
      <h2>{{$t("clutch.header")}}</h2>
      <p>{{$t("clutch.p1")}}</p>
      <p>{{$t("clutch.p2")}}</p>
      <p>{{$t("clutch.p3")}}</p>
      <p>{{$t("clutch.p4")}}</p>
      <p>{{$t("clutch.p5")}}</p>
      <img class="clutch-image" src="/img/clutch.png">
      <p>{{$t("clutch.p6")}}</p>
      <p>{{$t("clutch.p7")}}</p>
      <p>{{$t("clutch.p8")}}</p>
      <p>{{$t("clutch.p9")}}</p>
      <img class="clutch-image-client" src="/img/clutch-client.png">
      <p>{{$t("clutch.p10")}}</p>
      <p class="last-clutch-sentence">{{$t("clutch.p11")}}</p>
    </section>
  </main>
</template>

<script>

    export default {
    }
</script>