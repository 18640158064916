<template>
  <div>
    <div class="wrap-blog">
      <div class="wrapper">
        <div class="blog-illustration">
          <img srcset="/img/images/blog/Blog_illustration.png" alt width="547" height="391" />
        </div>
        <section class="wrapper-articles">
          <div class="blog-article" v-for="article in articles" :key="article.slug">
            <router-link :to="'/post/' +article.slug">
              <img v-if="article.image !== 'storage/'" :src="article.image" :alt="article.title" />
              <div v-if="article.image === 'storage/'" class="image-stub"></div>
              <div class="article-title">{{article.title}}</div>
            </router-link>
          </div>
          <div v-if="articlesInDevelopment === 0" class></div>
          <div
            v-if="articlesInDevelopment === 1"
            class="blog-article article-in-development"
          >Article in development</div>
          <div
            v-if="articlesInDevelopment === 1"
            class="blog-article article-in-development"
          >Article in development</div>
          <div
            v-if="articlesInDevelopment === 2 || articlesInDevelopment === 3"
            class="blog-article article-in-development"
          >Article in development</div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { requestGet, requestPost } from "../../services/axios";

let vm = {};

export default {
  name: "blog",
  components: {
  },
  data() {
    return {
      articles: [],
      articlesInDevelopment: 0
    };
  },
  beforeCreate() {
    vm = this;
  },
  mounted: () => {
    vm.getPosts();
  },
  methods: {
    getPosts: () => {
      requestGet("/avalablePosts", {}).then(response => {
        if (response.status) {
          vm.articles = response.data;
          console.log(vm.articles);
          const width = window.innerWidth;
          if (width > 1014) {
            vm.articlesInDevelopment = vm.articles.length % 3;
          } else if (width > 690) {
            if (vm.articles.length % 2 === 1) {
              vm.articlesInDevelopment = 3;
            } else {
              vm.articlesInDevelopment = 0;
            }
          } else {
            vm.articlesInDevelopment = 0;
          }
        }
      });
    }
  }
};
</script>