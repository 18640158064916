<template>
  <div class="wrapper-faq-section">
    <div class="wrap-faq">
      <div class="wrapper">
        <section class="faq-section">
          <h1>Faq</h1>
          <h2>{{$t("faq.faq-title")}}</h2>
          <ul class="faq-list">
            <li v-for="item in questions" :key="item.question" @click="toggleOpenPost(item.id)">
              <FaqItem
                :id="item.id"
                :question="item.question"
                :answer="item.answer"
                :openId="openId"
                :makeOpen="makeOpen"
              />
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import FaqItem from "./FaqItem";
import { log } from "util";
let vm = {};
export default {
  name: "faq",
  components: {
    FaqItem
  },
  data() {
    return {
      // questions: [
      //   {
      //     id: 1,
      //     question: "faq.domain",
      //     answer: `<p> ${vm.$t("faq.domain-answer1")} <strong>${vm.$t("faq.domain-name")}</strong>${vm.$t("faq.domain-answer2")} <strong>${vm.$t("faq.domain-address")}</strong> ${vm.$t("faq.domain-answer3")} <strong>${vm.$t("faq.domain-website")} </strong>. ${vm.$t("faq.domain-answer4")}  <a href="https://bozkadesign.com/"> bozkadesign.com </a></p>`
      //     // answer:"faq.domain-answer"
      //   },
      //   {
      //     id: 2,
      //     question: "faq.choose-domain",
      //     answer: `<p> ${vm.$t("faq.choose-domain-answer1")} <strong>${vm.$t("faq.choose-domain-answer2")}<strong></p>`
      //   },
      //   {
      //     id: 3,
      //     question: "faq.buy-domain",
      //     answer: `<p> ${vm.$t("faq.buy-domain-answer")}</p>
      //     <div class="important-note">${vm.$t("faq.important-note")}</div>
      //     <ol>
      //     <li>${vm.$t("faq.buy-domain-note1")}</li>
      //     <li>${vm.$t("faq.buy-domain-note2")}
      //       <a href="https://de.godaddy.com/help/gewahren-von-fremdzugriff-auf-das-eigene-godaddy-konto-12376">${vm.$t("faq.buy-domain-note3")}</a>
      //     ${vm.$t("faq.buy-domain-note4")} ${vm.$t("faq.buy-domain-note5")}</li>
      //     </ol>`
      //   },
      //   {
      //     id: 4,
      //     question: "faq.hosting",
      //     answer: `
      //     <ol>
      //       <li><strong>${vm.$t("faq.hosting-answer1")}</strong> ${vm.$t("faq.hosting-answer2")}
      //         <ul class="hosting-list">
      //           <li>${vm.$t("faq.hosting-item1")}</li>
      //           <li>${vm.$t("faq.hosting-item2")}</li>
      //           <li>${vm.$t("faq.hosting-item3")}</li>
      //           <li>${vm.$t("faq.hosting-item4")}</li>
      //           <li>${vm.$t("faq.hosting-item5")}</li>
      //         </ul>
      //         ${vm.$t("faq.hosting-answer3")}
      //         <p><span class="important-note">${vm.$t("faq.important-note")}</span> ${vm.$t("faq.hosting-answer4")}${vm.$t("faq.hosting-answer5")} ${vm.$t("faq.hosting-answer6")}</p>
      //       </li>
      //       <li><strong>${vm.$t("faq.hosting-answer7")}</strong> ${vm.$t("faq.hosting-answer8")}</li>
      //       <li>${vm.$t("faq.hosting-answer9")} <strong>${vm.$t("faq.hosting-answer10")}</strong> ${vm.$t("faq.hosting-answer11")}</li>
      //       <li><strong>${vm.$t("faq.hosting-answer12")}</strong> ${vm.$t("faq.hosting-answer13")}</li>
      //       <li>${vm.$t("faq.hosting-answer14")} <strong>${vm.$t("faq.hosting-answer15")}</strong> ${vm.$t("faq.hosting-answer16")} ${vm.$t("faq.hosting-answer20")}</li>
      //       <li>${vm.$t("faq.hosting-answer17")} <strong>${vm.$t("faq.hosting-answer18")}</strong> ${vm.$t("faq.hosting-answer19")}</li>
      //     </ol>`
      //   },
      //   {
      //     id: 5,
      //     question: "faq.cloud-hosting",
      //     answer: `<p>${vm.$t("faq.cloud-answer1")}</p><p>${vm.$t("faq.cloud-answer2")}</p>
      //     <div>${vm.$t("faq.cloud-answer3")}</div>
      //     <ol>
      //     <li>${vm.$t("faq.cloud-answer4")} ${vm.$t("faq.cloud-answer5")} <a href="https://support.google.com/accounts/answer/185833?hl=en">https://support.google.com/accounts/answer/185833?hl=en</a> ${vm.$t("faq.cloud-answer6")}
      //     <p><span class="important-note">${vm.$t("faq.important-note")}</span> ${vm.$t("faq.cloud-answer7")}</p></li>
      //     <li><b>${vm.$t("faq.cloud-answer8")}</b> ${vm.$t("faq.cloud-answer9")}<b>${vm.$t("faq.cloud-answer21")}</b></li>
      //     <li><b>${vm.$t("faq.cloud-answer10")}</b> ${vm.$t("faq.cloud-answer11")}</li>
      //     <li>${vm.$t("faq.cloud-answer12")}</li>
      //     </ol>
      //     <div>${vm.$t("faq.cloud-answer13")}</div>
      //     <ol>
      //     <li>${vm.$t("faq.cloud-answer14")}</li>
      //     <li><b>${vm.$t("faq.cloud-answer15")}</b> ${vm.$t("faq.cloud-answer16")}</li>
      //     <li>${vm.$t("faq.cloud-answer17")} <b>${vm.$t("faq.cloud-answer18")}</b> ${vm.$t("faq.cloud-answer19")}</li>
      //     <li>${vm.$t("faq.cloud-answer20")}</li>
      //     </ol>`
      //   },
      //   {
      //     id: 6,
      //     question: "faq.preparation1",
      //     answer: `<p>${vm.$t("faq.preparation-answer1")}</p>
      //     <p>${vm.$t("faq.preparation-answer1")}</p>
      //     <p>${vm.$t("faq.preparation-answer2")}</p>
      //     <p>${vm.$t("faq.preparation-answer3")}</p>
      //     <p>${vm.$t("faq.preparation-answer4")}</p>
      //     <p>${vm.$t("faq.preparation-answer5")}</p>
      //     <p>${vm.$t("faq.preparation-answer6")}</p>`
      //   },
      //   {
      //     id: 7,
      //     question: "faq.user-stories",
      //     answer: `<p>${vm.$t("faq.user-stories-answer1")}</p>
      //     <div class="example-video">
      //       <img src="/img/images/faq/user-stories.png" alt="user stories"/>
      //     </div>
      //     <p>${vm.$t("faq.user-stories-answer2")}</p>`
      //   },
      //   {
      //     id: 8,
      //     question: "faq.journey",
      //     answer: `<p>${vm.$t("faq.journey-answer1")}</p>
      //     <div class="example-video"><img src="/img/images/faq/user-journey.png" alt="user-journey"/></div>
      //     <p>${vm.$t("faq.journey-answer2")}</p>`
      //   },
      //   {
      //     id: 9,
      //     question: "faq.mapping",
      //     answer: `<p>${vm.$t("faq.mapping-answer1")}</p>
      //     <p>${vm.$t("faq.mapping-answer2")}</p>
      //     <p>${vm.$t("faq.mapping-answer3")}</p>
      //     <div class="example-video"><img src="/img/images/faq/mapping.png" alt="mapping.png"/></div>`
      //   },
      //   {
      //     id: 10,
      //     question: "faq.wireframes",
      //     answer: `<p>${vm.$t("faq.wireframes-answer1")}</p>
      //     <p>${vm.$t("faq.wireframes-answer2")}</p>
      //     <p>${vm.$t("faq.wireframes-answer3")}</p>
      //     <p>${vm.$t("faq.wireframes-answer4")}</p>
      //     <div class="example-video"><img src="/img/images/faq/user-stories.png" alt=""/></div>`
      //   },
      //   {
      //     id: 11,
      //     question: "faq.mockups",
      //     answer: `<p>${vm.$t("faq.mockups-answer1")}</p>
      //     <p>${vm.$t("faq.mockups-answer2")}</p>
      //     <p>${vm.$t("faq.mockups-answer3")}</p>
      //     <p>${vm.$t("faq.mockups-answer4")}</p>`
      //   },
      //   {
      //     id: 12,
      //     question: "faq.user-testing",
      //     answer: `<p>${vm.$t("faq.testing-answer1")}</p>
      //     <p>${vm.$t("faq.testing-answer2")}</p>
      //     <p>${vm.$t("faq.testing-answer3")}</p>
      //     <p>${vm.$t("faq.testing-answer4")}</p>
      //     <p>${vm.$t("faq.testing-answer5")}</p>
      //     <div class="example-video"><img src="/img/images/faq/user-testing.png" alt="user testing"/></div>
      //     <div class="example-video"><img src="/img/images/faq/user-stories.png" alt=""/></div>`
      //   }
      // ],
      makeOpen: true,
      openId: 0,
      lang: "en"
    };
  },
  beforeCreate() {
    vm = this;
  },
    mounted() {
        $('.faq-item').on('click', function (event) {
            let minusHeight = window.innerWidth > 764 ? 140 : 80;
            const activeClass = 'active';
            const that = this;
            $('.faq-item').not(that).removeClass(activeClass)
            event.stopPropagation();
            $('.faq-item-answer').css('display', 'none');
            if (!$(that).hasClass(activeClass)) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(this).offset().top - minusHeight
                }, {
                    duration: 300,
                    complete: function() {
                        $(that).find('.faq-item-answer').show(250);
                    }
                });
            }
            $(that).toggleClass(activeClass);
        })
    },
    methods: {
    toggleOpenPost(id) {
      vm.$forceUpdate();
      if (vm.openId == id) {
        vm.makeOpen = !vm.makeOpen;
      } else {
        vm.makeOpen = true;
        vm.openId = id;
      }
    }
  },
  computed: {
    questions: () => {
      return [
                {
          id: 1,
          question: "faq.minimum-size",
          answer: `<p>${vm.$t("faq.minimum-size-answer1")}</p>`
        },
        {
          id: 2,
          question: "faq.payment",
          answer: `<p>${vm.$t("faq.payment-answer1")}</p>
          <p>${vm.$t("faq.payment-answer2")}</p>
          <p>${vm.$t("faq.payment-answer3")}</p>
          <p>${vm.$t("faq.payment-answer4")}</p>
          `
        },
        {
          id: 3,
          question: "faq.user-testing",
          answer: `<p>${vm.$t("faq.testing-answer1")}</p>
          <p>${vm.$t("faq.testing-answer2")}</p>
          <p>${vm.$t("faq.testing-answer3")}</p>
          <p>${vm.$t("faq.testing-answer4")}</p>
          <p>${vm.$t("faq.testing-answer5")}</p>
          <div class="example-video"><img src="/img/images/faq/user-testing.png" alt="user testing"/></div>
          <div class="example-video"><img src="/img/images/faq/user-stories.png" alt=""/></div>`
        },
        {
          id: 4,
          question: "faq.preparation1",
          answer: `<p>${vm.$t("faq.preparation-answer1")}</p>
          <p>${vm.$t("faq.preparation-answer1")}</p>
          <p>${vm.$t("faq.preparation-answer2")}</p>
          <p>${vm.$t("faq.preparation-answer3")}</p>
          <p>${vm.$t("faq.preparation-answer4")}</p>
          <p>${vm.$t("faq.preparation-answer5")}</p>
          <p>${vm.$t("faq.preparation-answer6")}</p>`
        },
        {
          id: 7,
          question: "faq.user-stories",
          answer: `<p>${vm.$t("faq.user-stories-answer1")}</p>
          <div class="example-video">
            <img src="/img/images/faq/user-stories.png" alt="user stories"/>
          </div>
          <p>${vm.$t("faq.user-stories-answer2")}</p>`
        },
        {
          id: 8,
          question: "faq.journey",
          answer: `<p>${vm.$t("faq.journey-answer1")}</p>
          <div class="example-video"><img src="/img/images/faq/user-journey.png" alt="user-journey"/></div>
          <p>${vm.$t("faq.journey-answer2")}</p>`
        },
        {
          id: 9,
          question: "faq.mapping",
          answer: `<p>${vm.$t("faq.mapping-answer1")}</p>
          <p>${vm.$t("faq.mapping-answer2")}</p>
          <p>${vm.$t("faq.mapping-answer3")}</p>
          <div class="example-video"><img src="/img/images/faq/mapping.png" alt="mapping.png"/></div>`
        },
        {
          id: 10,
          question: "faq.wireframes",
          answer: `<p>${vm.$t("faq.wireframes-answer1")}</p>
          <p>${vm.$t("faq.wireframes-answer2")}</p>
          <p>${vm.$t("faq.wireframes-answer3")}</p>
          <p>${vm.$t("faq.wireframes-answer4")}</p>
          <div class="example-video"><img src="/img/images/faq/user-stories.png" alt=""/></div>`
        },
        {
          id: 11,
          question: "faq.mockups",
          answer: `<p>${vm.$t("faq.mockups-answer1")}</p>
          <p>${vm.$t("faq.mockups-answer2")}</p>
          <p>${vm.$t("faq.mockups-answer3")}</p>
          <p>${vm.$t("faq.mockups-answer4")}</p>`
        },
        // {
        //   id: 12,
        //   question: "faq.user-testing",
        //   answer: `<p>${vm.$t("faq.testing-answer1")}</p>
        //   <p>${vm.$t("faq.testing-answer2")}</p>
        //   <p>${vm.$t("faq.testing-answer3")}</p>
        //   <p>${vm.$t("faq.testing-answer4")}</p>
        //   <p>${vm.$t("faq.testing-answer5")}</p>
        //   <div class="example-video"><img src="/img/images/faq/user-testing.png" alt="user testing"/></div>
        //   <div class="example-video"><img src="/img/images/faq/user-stories.png" alt=""/></div>`
        // },
        {
          id: 13,
          question: "faq.domain",
          answer: `<p> ${vm.$t("faq.domain-answer1")} <strong>${vm.$t(
            "faq.domain-name"
          )}</strong>${vm.$t("faq.domain-answer2")} <strong>${vm.$t(
            "faq.domain-address"
          )}</strong> ${vm.$t("faq.domain-answer3")} <strong>${vm.$t(
            "faq.domain-website"
          )} </strong>. ${vm.$t(
            "faq.domain-answer4"
          )}  <a href="https://bozkadesign.com/"> bozkadesign.com </a></p>`
          // answer:"faq.domain-answer"
        },
        {
          id: 14,
          question: "faq.choose-domain",
          answer: `<p> ${vm.$t("faq.choose-domain-answer1")} <strong>${vm.$t(
            "faq.choose-domain-answer2"
          )}<strong></p>`
        },
        {
          id: 15,
          question: "faq.buy-domain",
          answer: `<p> ${vm.$t("faq.buy-domain-answer")}</p>
          <div class="important-note">${vm.$t("faq.important-note")}</div>
          <ol>
          <li>${vm.$t("faq.buy-domain-note1")}</li>
          <li>${vm.$t("faq.buy-domain-note2")}
            <a href="https://de.godaddy.com/help/gewahren-von-fremdzugriff-auf-das-eigene-godaddy-konto-12376">${vm.$t(
              "faq.buy-domain-note3"
            )}</a>
          ${vm.$t("faq.buy-domain-note4")} ${vm.$t("faq.buy-domain-note5")}</li>
          </ol>`
        },
        {
          id: 16,
          question: "faq.hosting",
          answer: `
          <ol>
            <li><strong>${vm.$t("faq.hosting-answer1")}</strong> ${vm.$t(
            "faq.hosting-answer2"
          )}
              <ul class="hosting-list">
                <li>${vm.$t("faq.hosting-item1")}</li>
                <li>${vm.$t("faq.hosting-item2")}</li>
                <li>${vm.$t("faq.hosting-item3")}</li>
                <li>${vm.$t("faq.hosting-item4")}</li>
                <li>${vm.$t("faq.hosting-item5")}</li>
              </ul>
              ${vm.$t("faq.hosting-answer3")}
              <p><span class="important-note">${vm.$t(
                "faq.important-note"
              )}</span> ${vm.$t("faq.hosting-answer4")}${vm.$t(
            "faq.hosting-answer5"
          )} ${vm.$t("faq.hosting-answer6")}</p>
            </li>
            <li><strong>${vm.$t("faq.hosting-answer7")}</strong> ${vm.$t(
            "faq.hosting-answer8"
          )}</li>
            <li>${vm.$t("faq.hosting-answer9")} <strong>${vm.$t(
            "faq.hosting-answer10"
          )}</strong> ${vm.$t("faq.hosting-answer11")}</li>
            <li><strong>${vm.$t("faq.hosting-answer12")}</strong> ${vm.$t(
            "faq.hosting-answer13"
          )}</li>
            <li>${vm.$t("faq.hosting-answer14")} <strong>${vm.$t(
            "faq.hosting-answer15"
          )}</strong> ${vm.$t("faq.hosting-answer16")} ${vm.$t(
            "faq.hosting-answer20"
          )}</li>
            <li>${vm.$t("faq.hosting-answer17")} <strong>${vm.$t(
            "faq.hosting-answer18"
          )}</strong> ${vm.$t("faq.hosting-answer19")}</li>
          </ol>`
        },
        {
          id: 17,
          question: "faq.cloud-hosting",
          answer: `<p>${vm.$t("faq.cloud-answer1")}</p><p>${vm.$t(
            "faq.cloud-answer2"
          )}</p>
          <div>${vm.$t("faq.cloud-answer3")}</div>
          <ol>
          <li>${vm.$t("faq.cloud-answer4")} ${vm.$t(
            "faq.cloud-answer5"
          )} <a href="https://support.google.com/accounts/answer/185833?hl=en">https://support.google.com/<wbr>accounts/answer/185833?hl=en</a> ${vm.$t(
            "faq.cloud-answer6"
          )}
          <p><span class="important-note">${vm.$t(
            "faq.important-note"
          )}</span> ${vm.$t("faq.cloud-answer7")}</p></li>
          <li><b>${vm.$t("faq.cloud-answer8")}</b> ${vm.$t(
            "faq.cloud-answer9"
          )}<b>${vm.$t("faq.cloud-answer21")}</b></li>
          <li><b>${vm.$t("faq.cloud-answer10")}</b> ${vm.$t(
            "faq.cloud-answer11"
          )}</li>
          <li>${vm.$t("faq.cloud-answer12")}</li>
          </ol>
          <div>${vm.$t("faq.cloud-answer13")}</div>
          <ol>
          <li>${vm.$t("faq.cloud-answer14")}</li>
          <li><b>${vm.$t("faq.cloud-answer15")}</b> ${vm.$t(
            "faq.cloud-answer16"
          )}</li>
          <li>${vm.$t("faq.cloud-answer17")} <b>${vm.$t(
            "faq.cloud-answer18"
          )}</b> ${vm.$t("faq.cloud-answer19")}</li>
          <li>${vm.$t("faq.cloud-answer20")}</li>
          </ol>`
        }
      ];
    }
  }
};
</script>
