<template>
    <!--SECTION OUR TEAM-->
    <div id="team" class="section-our-team">
        <div class="">
            <div class="container-team">
                <h2 class="main-title">{{$t("section.team")}}</h2>
                <div class="wrap-founder wrapper row">
                    <div class="founder">
                        <a href="https://www.linkedin.com/in/ninaklymenko/" target="_blank">
                            <video id="team-video-1" poster="/img/Nina.jpg" style="object-fit: fill;" preload="auto" muted width="384" height="414" type='video/mov' playsinline>
                                <source src="/video/Nina.mov">
                            </video>
                        </a>

                        <div class="wrap-founder-name">
                            <a href="https://www.linkedin.com/in/ninaklymenko/" target="_blank" class="wrap-name">
                                <span>{{$t("team.ceo.name")}}</span>
                                <span class="last-name">{{$t("team.ceo.surname")}}</span>
                            </a>
                            <span class="position">{{$t("team.ceo.position")}}</span>
                        </div>
                    </div>

                    <div class="founder">
                        <a href="https://www.linkedin.com/in/maksym-tsumbalist-87a85771/" target="_blank">
                            <video id="team-video-2" poster="/img/Max.jpg" style="object-fit: fill;" preload="auto" muted width="384" height="414" type='video/mov' playsinline>
                                <source src="/video/Max.mov">
                            </video>
                        </a>

                        <div class="wrap-founder-name">
                            <a href="https://www.linkedin.com/in/maksym-tsumbalist-87a85771/" target="_blank" class="wrap-name">
                                <span>{{$t("team.ux-designer.name")}}</span>
                                <span class="last-name">{{$t("team.ux-designer.surname")}}</span>
                            </a>
                            <span class="position">{{$t("team.ux-designer.position")}}</span>
                        </div>
                    </div>


                 </div>
                     <div class="scills-team wrapper">

                            <img class="img-skills" src="/img/images/Skills.png" alt="">
                            <div class="description-founder">
                                 <p>{{$t("team.ceo.description-first")}}</p>
                                 <p>{{$t("team.ceo.description-second")}}</p>
                            </div>

                        <div class="svg-scills">
                            <svg  width="1019px" height="677px" viewBox="0 0 1019 677" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>2EEDEFBC-299B-4354-BD04-037ED7D9D249</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-6">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-7">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-8">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-9">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-10">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-11">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-12">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-13">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-14">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-15">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-16">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-17">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-18">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-19">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-20">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-21">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-22">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-23">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-24">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-25">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-26">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="main-long" transform="translate(-169.000000, -5924.000000)">
            <g id="Network-image" transform="translate(169.000000, 5925.000000)">
                <g id="Group-165" opacity="0.5" transform="translate(509.000000, 337.500000) rotate(-90.000000) translate(-509.000000, -337.500000) translate(171.500000, -171.500000)">
                    <line x1="466.712356" y1="63.5565076" x2="383.7273" y2="195.1225" id="Stroke-1" stroke="url(#linearGradient-1)" stroke-linecap="round" stroke-linejoin="round"></line>
                    <polyline id="Stroke-2" stroke="url(#linearGradient-2)" stroke-linecap="round" stroke-linejoin="round" points="674.7011 819.4481 614.7681 843.1381 636.8941 792.7541 601.5371 764.2711 532.5441 788.1771 484.1361 738.9651 564.0131 636.9821 465.2841 659.4641 398.8241 642.2721 441.6491 597.2961 493.0911 593.5931 427.7741 496.0031 535.9211 412.2221 562.9471 546.3701 605.6281 601.1421 657.9451 563.5111 604.5921 437.7711 589.2541 384.5091 540.1281 387.4411 457.8261 373.0271 535.9211 412.2221 493.0911 593.5931 562.9471 546.3701 564.0131 636.9821"></polyline>
                    <polyline id="Stroke-4" stroke="url(#linearGradient-3)" stroke-linecap="round" stroke-linejoin="round" points="426.4326 324.7352 323.0086 416.4972 427.7746 496.0032 348.3596 531.1002 253.6156 534.8962 223.1656 506.3282"></polyline>
                    <polygon id="Stroke-5" stroke="url(#linearGradient-4)" stroke-linecap="round" stroke-linejoin="round" points="672.9687 725.7586 641.1677 657.8216 595.3627 728.3606 601.5367 764.2716"></polygon>
                    <polyline id="Stroke-6" stroke="url(#linearGradient-5)" stroke-linecap="round" stroke-linejoin="round" points="670.8632 611.9061 605.6282 601.1421 595.3622 728.3601 672.9682 725.7591"></polyline>
                    <polyline id="Stroke-7" stroke="url(#linearGradient-6)" stroke-linecap="round" stroke-linejoin="round" points="668.2255 469.2625 657.9455 563.5105 670.2615 579.3525"></polyline>
                    <polyline id="Stroke-8" stroke="url(#linearGradient-7)" stroke-linecap="round" stroke-linejoin="round" points="323.0087 416.4969 300.1337 285.6339 228.9207 389.8629 323.0087 416.4969 457.8257 373.0269 426.4327 324.7349 540.1277 387.4409 609.1497 286.9159 664.4987 267.7339"></polyline>
                    <polyline id="Stroke-9" stroke="url(#linearGradient-8)" stroke-linecap="round" stroke-linejoin="round" points="484.1357 738.9647 465.2837 659.4637 456.6587 724.2147 351.3567 638.2207 380.863259 575.5939 398.8237 642.2727 493.0907 593.5927 465.2837 659.4637 562.9477 546.3697 657.9457 563.5107 535.9207 412.2227 604.5917 437.7717 667.3127 419.8637"></polyline>
                    <polyline id="Stroke-10" stroke="url(#linearGradient-9)" stroke-linecap="round" stroke-linejoin="round" points="647.374 333.3719 589.254 384.5089 609.149 286.9159 426.433 324.7349 542.355 255.5739 479.412 224.7969 559.483 179.6659 563.652 102.8049 634.579 160.6159 559.483 179.6659 626.478 235.4059"></polyline>
                    <polyline id="Stroke-11" stroke="url(#linearGradient-10)" stroke-linecap="round" stroke-linejoin="round" points="383.7275 195.1229 300.1335 285.6339 202.7215 307.0749 228.9205 389.8629 348.3595 531.1009 323.0085 416.4969 535.9205 412.2229 540.1275 387.4409 604.5915 437.7709 647.3735 333.3719 609.1495 286.9159 542.3555 255.5739 626.4775 235.4059 634.5795 160.6159 662.1255 139.3839"></polyline>
                    <polyline id="Stroke-12" stroke="url(#linearGradient-11)" stroke-linecap="round" stroke-linejoin="round" points="662.9511 184.0252 626.4771 235.4062 563.6521 102.8042 479.4121 224.7962 426.4321 324.7352 300.1341 285.6332"></polyline>
                    <polyline id="Stroke-13" stroke="url(#linearGradient-12)" stroke-linecap="round" stroke-linejoin="round" points="609.1494 286.9158 626.4774 235.4058 479.4124 224.7968 504.7044 152.9748 563.6524 102.8048 620.7324 0.6158"></polyline>
                    <polyline id="Stroke-15" stroke="url(#linearGradient-13)" stroke-linecap="round" stroke-linejoin="round" points="228.9208 389.8631 223.1658 506.3281 348.3588 531.1001 380.2787 575.5937"></polyline>
                    <polyline id="Stroke-16" stroke="url(#linearGradient-14)" stroke-linecap="round" stroke-linejoin="round" points="673.3027 743.7967 636.8947 792.7537 674.4717 807.0127"></polyline>
                    <polyline id="Stroke-18" stroke="url(#linearGradient-15)" stroke-linecap="round" stroke-linejoin="round" points="666.371 368.9598 647.374 333.3718 664.88 288.3398"></polyline>
                    <polyline id="Stroke-19" stroke="url(#linearGradient-16)" stroke-linecap="round" stroke-linejoin="round" points="351.3564 638.2205 301.5614 724.2345 456.6594 724.2145 398.8244 642.2725 351.3564 638.2205 304.7104 642.2545 104.7557 825.309888 253.6154 534.8965 380.862959 575.5937 427.7744 496.0025 441.6494 597.2955 380.862959 575.5937 304.7104 642.2545 253.6154 534.8965"></polyline>
                    <polyline id="Stroke-20" stroke="url(#linearGradient-17)" stroke-linecap="round" stroke-linejoin="round" points="463.052432 912.156015 447.220926 1018 321.0745 814.3955 447.220926 798.328774 463.052432 912.156015 321.0745 814.3955 456.6595 724.2145"></polyline>
                    <polyline id="Stroke-22" stroke="url(#linearGradient-18)" stroke-linecap="round" stroke-linejoin="round" points="504.704 152.9744 383.727 195.1224 479.412 224.7964 300.134 285.6334"></polyline>
                    <polyline id="Stroke-23" stroke="url(#linearGradient-19)" stroke-linecap="round" stroke-linejoin="round" points="301.5615 724.2342 104.7548 825.309588 321.0745 814.3952 301.5615 724.2342 304.7105 642.2552"></polyline>
                    <line x1="625.787" y1="878.9656" x2="491.538" y2="875.5356" id="Stroke-24" stroke="url(#linearGradient-20)" stroke-linecap="round" stroke-linejoin="round"></line>
                    <polyline id="Stroke-26" stroke="url(#linearGradient-21)" stroke-linecap="round" stroke-linejoin="round" points="223.166 506.3279 181.3564 584.119 104.7553 825.310288"></polyline>
                    <polygon id="Stroke-27" stroke="url(#linearGradient-22)" stroke-linecap="round" stroke-linejoin="round" points="178.274 182.451824 283.274 227.9364 247.476975 128.9364"></polygon>
                    <polygon id="Stroke-27-Copy" stroke="url(#linearGradient-23)" stroke-linecap="round" stroke-linejoin="round" transform="translate(261.954000, 928.085400) rotate(13.000000) translate(-261.954000, -928.085400) " points="202.454 931.573546 321.454 971.0854 280.884039 885.0854"></polygon>
                    <polygon id="Stroke-30" stroke="url(#linearGradient-24)" stroke-linecap="round" stroke-linejoin="round" points="0.9941 518.2088 34.3151 584.5608 53.2711 531.6238"></polygon>
                    <polygon id="Stroke-32" stroke="url(#linearGradient-25)" stroke-linecap="round" stroke-linejoin="round" points="51.4653 808.9002 79.6473 738.7682 0.2553 694.0472"></polygon>
                    <polygon id="Stroke-32-Copy" stroke="url(#linearGradient-26)" stroke-linecap="round" stroke-linejoin="round" transform="translate(104.755300, 397.047200) scale(-1, 1) translate(-104.755300, -397.047200) " points="110.701415 427.0472 125.2553 390.409759 84.2553 367.0472"></polygon>
                    <g id="Group-164" transform="translate(27.000000, 301.581000)" fill="#B937DB">
                        <path d="M196.166256,200.7465 C198.375426,200.7465 200.1665,202.537465 200.1665,204.746012 C200.1665,206.955535 198.375426,208.7465 196.166256,208.7465 C193.957574,208.7465 192.1665,206.955535 192.1665,204.746012 C192.1665,202.537465 193.957574,200.7465 196.166256,200.7465 Z" id="Stroke-38"></path>
                        <path d="M175.7216,358.2567 C178.4356,358.2567 180.6356,360.4557 180.6356,363.1707 C180.6356,365.8847 178.4356,368.0847 175.7216,368.0847 C173.0076,368.0847 170.8076,365.8847 170.8076,363.1707 C170.8076,360.4557 173.0076,358.2567 175.7216,358.2567 Z" id="Stroke-46" opacity="0.5"></path>
                        <path d="M387.524051,127.293834 C388.793965,126.229395 390.686201,126.396057 391.750263,127.665985 C392.814325,128.935547 392.647664,130.827803 391.377749,131.892242 C390.108201,132.956315 388.215965,132.789653 387.151537,131.519725 C386.087475,130.249796 386.254136,128.357907 387.524051,127.293834 Z" id="Stroke-54" opacity="0.5"></path>
                        <path d="M424.1259,123.5643 C428.7469,119.6913 435.6329,120.2973 439.5059,124.9183 C443.3779,129.5403 442.7719,136.4253 438.1499,140.2983 C433.5289,144.1703 426.6439,143.5643 422.7719,138.9433 C418.8979,134.3223 419.5049,127.4363 424.1259,123.5643 Z" id="Stroke-56"></path>
                        <path d="M427.9814,128.1649 C430.0624,126.4219 433.1614,126.6939 434.9044,128.7739 C436.6474,130.8539 436.3754,133.9539 434.2944,135.6969 C432.2144,137.4399 429.1154,137.1679 427.3724,135.0879 C425.6284,133.0079 425.9024,129.9079 427.9814,128.1649 Z" id="Stroke-58"></path>
                        <path d="M441.2519,178.6532 C445.8729,174.7802 452.7589,175.3862 456.6309,180.0072 C460.5039,184.6292 459.8979,191.5142 455.2759,195.3872 C450.6549,199.2592 443.7699,198.6532 439.8969,194.0322 C436.0239,189.4112 436.6309,182.5252 441.2519,178.6532 Z" id="Stroke-60"></path>
                        <path d="M445.1074,183.2538 C447.1874,181.5108 450.2874,181.7838 452.0304,183.8638 C453.7734,185.9438 453.5014,189.0428 451.4204,190.7858 C449.3404,192.5298 446.2414,192.2568 444.4984,190.1768 C442.7544,188.0968 443.0274,184.9968 445.1074,183.2538 Z" id="Stroke-62"></path>
                        <path d="M389.469118,263.150053 C390.382221,261.139271 392.752871,260.249605 394.76414,261.162709 C396.77492,262.075813 397.664585,264.445978 396.751482,266.457249 C395.838379,268.468519 393.467729,269.358185 391.45646,268.445081 C389.44568,267.531977 388.556015,265.161324 389.469118,263.150053 Z" id="Stroke-66" opacity="0.200000003"></path>
                        <path d="M429.7724,253.3778 C432.2654,247.8888 438.7364,245.4588 444.2264,247.9518 C449.7154,250.4448 452.1454,256.9168 449.6524,262.4058 C447.1594,267.8958 440.6884,270.3248 435.1984,267.8318 C429.7094,265.3388 427.2794,258.8678 429.7724,253.3778 Z" id="Stroke-68"></path>
                        <path d="M435.2382,255.8602 C436.3602,253.3892 439.2732,252.2962 441.7442,253.4182 C444.2162,254.5402 445.3082,257.4532 444.1862,259.9232 C443.0642,262.3952 440.1512,263.4882 437.6802,262.3662 C435.2102,261.2442 434.1162,258.3312 435.2382,255.8602 Z" id="Stroke-70"></path>
                        <path d="M469.0283,295.6522 C471.5213,290.1622 477.9933,287.7322 483.4823,290.2252 C488.9713,292.7182 491.4013,299.1902 488.9083,304.6792 C486.4163,310.1702 479.9443,312.5982 474.4543,310.1052 C468.9653,307.6122 466.5353,301.1412 469.0283,295.6522 Z" id="Stroke-72"></path>
                        <path d="M474.4941,298.1337 C475.6161,295.6627 478.5291,294.5697 481.0001,295.6917 C483.4721,296.8137 484.5651,299.7267 483.4421,302.1977 C482.3201,304.6687 479.4081,305.7617 476.9361,304.6397 C474.4661,303.5177 473.3721,300.6057 474.4941,298.1337 Z" id="Stroke-74"></path>
                        <path d="M295.818967,110.7264 C298.028138,110.7264 299.819212,112.517365 299.819212,114.725912 C299.819212,116.935435 298.028138,118.7264 295.818967,118.7264 C293.610286,118.7264 291.819212,116.935435 291.819212,114.725912 C291.819212,112.517365 293.610286,110.7264 295.818967,110.7264 Z" id="Stroke-78"></path>
                        <path d="M175.7216,0.58 C178.4356,0.58 180.6356,2.78 180.6356,5.494 C180.6356,8.209 178.4356,10.408 175.7216,10.408 C173.0076,10.408 170.8076,8.209 170.8076,5.494 C170.8076,2.78 173.0076,0.58 175.7216,0.58 Z" id="Stroke-82"></path>
                        <path d="M400.3974,18.1444 C403.15869,18.1444 405.3974,20.3831103 405.3974,23.1444 C405.3974,25.9056897 403.15869,28.1444 400.3974,28.1444 C397.63611,28.1444 395.3974,25.9056897 395.3974,23.1444 C395.3974,20.3831103 397.63611,18.1444 400.3974,18.1444 Z" id="Stroke-88"></path>
                        <path d="M430.8261,64.8964 C434.4431,64.8964 437.3761,67.8294 437.3761,71.4464 C437.3761,75.0644 434.4431,77.9974 430.8261,77.9974 C427.2081,77.9974 424.2751,75.0644 424.2751,71.4464 C424.2751,67.8294 427.2081,64.8964 430.8261,64.8964 Z" id="Stroke-92"></path>
                        <path d="M430.8261,68.4979 C432.4541,68.4979 433.7741,69.8179 433.7741,71.4459 C433.7741,73.0749 432.4541,74.3939 430.8261,74.3939 C429.1971,74.3939 427.8781,73.0749 427.8781,71.4459 C427.8781,69.8179 429.1971,68.4979 430.8261,68.4979 Z" id="Stroke-94"></path>
                        <path d="M193.3124,339.5184 C194.9414,339.5184 196.2604,340.8374 196.2604,342.4664 C196.2604,344.0944 194.9414,345.4144 193.3124,345.4144 C191.6844,345.4144 190.3644,344.0944 190.3644,342.4664 C190.3644,340.8374 191.6844,339.5184 193.3124,339.5184 Z" id="Stroke-98" opacity="0.200000003"></path>
                        <path d="M280.338918,288.1719 C283.10054,288.1719 285.3393,290.410831 285.3393,293.1719 C285.3393,295.932969 283.10054,298.1719 280.338918,298.1719 C277.57806,298.1719 275.3393,295.932969 275.3393,293.1719 C275.3393,290.410831 277.57806,288.1719 280.338918,288.1719 Z" id="Stroke-100"></path>
                        <path d="M154.4218,237.9569 C156.0498,237.9569 157.3698,239.2769 157.3698,240.9059 C157.3698,242.5339 156.0498,243.8539 154.4218,243.8539 C152.7928,243.8539 151.4728,242.5339 151.4728,240.9059 C151.4728,239.2769 152.7928,237.9569 154.4218,237.9569 Z" id="Stroke-110" opacity="0.200000003"></path>
                        <path d="M161.390329,199.6119 C163.046844,199.6119 164.3901,200.955156 164.3901,202.611671 C164.3901,204.268644 163.046844,205.6119 161.390329,205.6119 C159.733356,205.6119 158.3901,204.268644 158.3901,202.611671 C158.3901,200.955156 159.733356,199.6119 161.390329,199.6119 Z" id="Stroke-112"></path>
                        <path d="M76.0591608,327.0826 C77.1634512,327.0826 78.0595,327.977444 78.0595,329.0826 C78.0595,330.187078 77.1634512,331.0826 76.0591608,331.0826 C74.9548705,331.0826 74.0595,330.187078 74.0595,329.0826 C74.0595,327.977444 74.9548705,327.0826 76.0591608,327.0826 Z" id="Stroke-122" opacity="0.699999988"></path>
                        <path d="M22.1454,331.9166 C24.3548656,331.9166 26.1454,333.707608 26.1454,335.916295 C26.1454,338.125592 24.3548656,339.9166 22.1454,339.9166 C19.936545,339.9166 18.1454,338.125592 18.1454,335.916295 C18.1454,333.707608 19.936545,331.9166 22.1454,331.9166 Z" id="Stroke-124"></path>
                        <path d="M6.09693916,361.3273 C7.20122947,361.3273 8.0966,362.222671 8.0966,363.326961 C8.0966,364.431929 7.20122947,365.3273 6.09693916,365.3273 C4.99264884,365.3273 4.0966,364.431929 4.0966,363.326961 C4.0966,362.222671 4.99264884,361.3273 6.09693916,361.3273 Z" id="Stroke-130" opacity="0.200000003"></path>
                        <path d="M344.772595,375.7457 C346.981892,375.7457 348.7729,377.536708 348.7729,379.745395 C348.7729,381.954692 346.981892,383.7457 344.772595,383.7457 C342.563908,383.7457 340.7729,381.954692 340.7729,379.745395 C340.7729,377.536708 342.563908,375.7457 344.772595,375.7457 Z" id="Stroke-132" opacity="0.800000012"></path>
                        <path d="M322.272786,386.7457 C323.101272,386.7457 323.7729,387.417328 323.7729,388.245586 C323.7729,389.074072 323.101272,389.7457 322.272786,389.7457 C321.444528,389.7457 320.7729,389.074072 320.7729,388.245586 C320.7729,387.417328 321.444528,386.7457 322.272786,386.7457 Z" id="Stroke-132-Copy-2" opacity="0.5"></path>
                        <path d="M293.772595,508.7457 C295.981892,508.7457 297.7729,510.536708 297.7729,512.745395 C297.7729,514.954692 295.981892,516.7457 293.772595,516.7457 C291.563908,516.7457 289.7729,514.954692 289.7729,512.745395 C289.7729,510.536708 291.563908,508.7457 293.772595,508.7457 Z" id="Stroke-132-Copy"></path>
                        <path d="M400.584789,189.2313 C403.346084,189.2313 405.584789,191.470006 405.584789,194.2313 C405.584789,196.993205 403.346084,199.2313 400.584789,199.2313 C397.823495,199.2313 395.5854,196.993205 395.5854,194.2313 C395.5854,191.470006 397.823495,189.2313 400.584789,189.2313 Z" id="Stroke-142"></path>
                        <path d="M353.278944,269.8202 C355.487626,269.8202 357.2787,271.611165 357.2787,273.819712 C357.2787,276.029235 355.487626,277.8202 353.278944,277.8202 C351.069774,277.8202 349.2787,276.029235 349.2787,273.819712 C349.2787,271.611165 351.069774,269.8202 353.278944,269.8202 Z" id="Stroke-146"></path>
                        <path d="M372.634139,336.5008 C374.843309,336.5008 376.633895,338.291765 376.633895,340.5008 C376.633895,342.709835 374.843309,344.5008 372.634139,344.5008 C370.424969,344.5008 368.633895,342.709835 368.633895,340.5008 C368.633895,338.291765 370.424969,336.5008 372.634139,336.5008 Z" id="Stroke-150"></path>
                        <path d="M6.7314,276.6893 C10.0449477,276.6893 12.7314,279.375752 12.7314,282.6893 C12.7314,286.002848 10.0449477,288.6893 6.7314,288.6893 C3.41785232,288.6893 0.7314,286.002848 0.7314,282.6893 C0.7314,279.375752 3.41785232,276.6893 6.7314,276.6893 Z" id="Stroke-152"></path>
                        <path d="M99.1986504,281.3378 C101.40815,281.3378 103.198834,283.128768 103.198834,285.3378 C103.198834,287.546832 101.40815,289.3378 99.1986504,289.3378 C96.9898838,289.3378 95.1988336,287.546832 95.1988336,285.3378 C95.1988336,283.128768 96.9898838,281.3378 99.1986504,281.3378 Z" id="Stroke-156" opacity="0.400000006"></path>
                        <path d="M50.6469,431.6366 C54.2649,431.6366 57.1969,434.5696 57.1969,438.1866 C57.1969,441.8046 54.2649,444.7376 50.6469,444.7376 C47.0299,444.7376 44.0969,441.8046 44.0969,438.1866 C44.0969,434.5696 47.0299,431.6366 50.6469,431.6366 Z" id="Stroke-160"></path>
                        <path d="M79.6469,516.6366 C83.2649,516.6366 86.1969,519.5696 86.1969,523.1866 C86.1969,526.8046 83.2649,529.7376 79.6469,529.7376 C76.0299,529.7376 73.0969,526.8046 73.0969,523.1866 C73.0969,519.5696 76.0299,516.6366 79.6469,516.6366 Z" id="Stroke-160-Copy-2"></path>
                        <path d="M57.6469,85.1871 C59.8563656,85.1871 61.6469,86.9781083 61.6469,89.1867947 C61.6469,91.3960917 59.8563656,93.1871 57.6469,93.1871 C55.438045,93.1871 53.6469,91.3960917 53.6469,89.1867947 C53.6469,86.9781083 55.438045,85.1871 57.6469,85.1871 Z" id="Stroke-160-Copy" transform="translate(57.646900, 89.187100) scale(-1, 1) translate(-57.646900, -89.187100) "></path>
                        <path d="M12.6469,384.2381 C14.2749,384.2381 15.5959,385.5581 15.5959,387.1871 C15.5959,388.8151 14.2749,390.1351 12.6469,390.1351 C11.0189,390.1351 9.6989,388.8151 9.6989,387.1871 C9.6989,385.5581 11.0189,384.2381 12.6469,384.2381 Z" id="Stroke-162"></path>
                    </g>
                </g>
                <text id="Web-Development" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="304.5" y="370.5">Web </tspan>
                    <tspan x="304.5" y="389.5">Development</tspan>
                </text>
                <text id="3D-and-2D-Illustrati" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="323.5" y="468.5">3D and 2D </tspan>
                    <tspan x="323.5" y="487.5">Illustrations</tspan>
                </text>
                <text id="Animations" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="548.5" y="329.5">Animations</tspan>
                </text>
                <text id="Technical-Writing" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="742.5" y="371.5">Technical </tspan>
                    <tspan x="742.5" y="390.5">Writing</tspan>
                </text>
                <text id="Devops" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="553.5" y="425.5">Devops</tspan>
                </text>
                <text id="Mobile-Development-Copy" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="601.5" y="489.5">Mobile </tspan>
                    <tspan x="601.5" y="508.5">Development</tspan>
                </text>
                <path d="M535.9793,416.4678 C538.74059,416.4678 540.9793,418.70651 540.9793,421.4678 C540.9793,424.22909 538.74059,426.4678 535.9793,426.4678 C533.21801,426.4678 530.9793,424.22909 530.9793,421.4678 C530.9793,418.70651 533.21801,416.4678 535.9793,416.4678 Z" id="Stroke-40" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M724.8348,368.3614 C727.59609,368.3614 729.8348,370.599652 729.8348,373.360942 C729.8348,376.122232 727.59609,378.3614 724.8348,378.3614 C722.07351,378.3614 719.8348,376.122232 719.8348,373.360942 C719.8348,370.599652 722.07351,368.3614 724.8348,368.3614 Z" id="Stroke-44" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M585.119,489.6436 C587.88029,489.6436 590.119,491.88231 590.119,494.6436 C590.119,497.404432 587.88029,499.6436 585.119,499.6436 C582.35771,499.6436 580.119,497.404432 580.119,494.6436 C580.119,491.88231 582.35771,489.6436 585.119,489.6436 Z" id="Stroke-48" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M307.158,468.3614 C309.91929,468.3614 312.158,470.60011 312.158,473.3614 C312.158,476.12269 309.91929,478.3614 307.158,478.3614 C304.39671,478.3614 302.158,476.12269 302.158,473.3614 C302.158,470.60011 304.39671,468.3614 307.158,468.3614 Z" id="Stroke-80" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M286.6336,370.8663 C289.39489,370.8663 291.6336,373.10501 291.6336,375.8663 C291.6336,378.62759 289.39489,380.8663 286.6336,380.8663 C283.87231,380.8663 281.6336,378.62759 281.6336,375.8663 C281.6336,373.10501 283.87231,370.8663 286.6336,370.8663 Z" id="Stroke-84" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M530.7889,321.6329 C533.55019,321.6329 535.7889,323.87161 535.7889,326.6329 C535.7889,329.39419 533.55019,331.6329 530.7889,331.6329 C528.02761,331.6329 525.7889,329.39419 525.7889,326.6329 C525.7889,323.87161 528.02761,321.6329 530.7889,321.6329 Z" id="Stroke-136" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
            </g>
        </g>
    </g>
                            </svg>
                        </div>
                        <div class="svg-scills-mobile">
                            <svg  width="1018" height="695" viewBox="0 0 1019 677" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>0DE68F20-A975-4468-89D3-92C3F4731B6F</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-6">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-7">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-8">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-9">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-10">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-11">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-12">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-13">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-14">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-15">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-16">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-17">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-18">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-19">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-20">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-21">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-22">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-23">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-24">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-25">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-26">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-27">
            <stop stop-color="#43234C" offset="0%"></stop>
            <stop stop-color="#BE2AFF" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="main-long-mobile" transform="translate(259.000000, -4957.000000)">
            <g id="Network-image_mobile" transform="translate(-259.000000, 4958.000000)">
                <g id="Group-165" opacity="0.5" transform="translate(509.000000, 347.500000) rotate(-90.000000) translate(-509.000000, -347.500000) translate(161.500000, -161.500000)">
                    <line x1="486.712356" y1="63.5565076" x2="403.7273" y2="195.1225" id="Stroke-1" stroke="url(#linearGradient-1)" stroke-linecap="round" stroke-linejoin="round"></line>
                    <polyline id="Stroke-2" stroke="url(#linearGradient-2)" stroke-linecap="round" stroke-linejoin="round" points="694.7011 819.4481 634.7681 843.1381 656.8941 792.7541 621.5371 764.2711 552.5441 788.1771 504.1361 738.9651 584.0131 636.9821 485.2841 659.4641 418.8241 642.2721 461.6491 597.2961 513.0911 593.5931 447.7741 496.0031 555.9211 412.2221 582.9471 546.3701 625.6281 601.1421 677.9451 563.5111 624.5921 437.7711 609.2541 384.5091 560.1281 387.4411 477.8261 373.0271 555.9211 412.2221 513.0911 593.5931 582.9471 546.3701 584.0131 636.9821"></polyline>
                    <polyline id="Stroke-4" stroke="url(#linearGradient-3)" stroke-linecap="round" stroke-linejoin="round" points="446.4326 324.7352 368.3597 440.9796 447.7746 496.0032 332.3401 495.8124 248.9207 553.799057 273.6152 445.7892"></polyline>
                    <polygon id="Stroke-5" stroke="url(#linearGradient-4)" stroke-linecap="round" stroke-linejoin="round" points="692.9687 725.7586 661.1677 657.8216 615.3627 728.3606 621.5367 764.2716"></polygon>
                    <polyline id="Stroke-6" stroke="url(#linearGradient-5)" stroke-linecap="round" stroke-linejoin="round" points="690.8632 611.9061 625.6282 601.1421 615.3622 728.3601 692.9682 725.7591"></polyline>
                    <polyline id="Stroke-7" stroke="url(#linearGradient-6)" stroke-linecap="round" stroke-linejoin="round" points="688.2255 469.2625 677.9455 563.5105 690.2615 579.3525"></polyline>
                    <polyline id="Stroke-8" stroke="url(#linearGradient-7)" stroke-linecap="round" stroke-linejoin="round" points="368.3598 440.9793 320.1337 285.6339 248.9207 389.8629 368.3598 440.9793 477.8257 373.0269 446.4327 324.7349 560.1277 387.4409 629.1497 286.9159 684.4987 267.7339"></polyline>
                    <polyline id="Stroke-9" stroke="url(#linearGradient-8)" stroke-linecap="round" stroke-linejoin="round" points="504.1357 738.9647 485.2837 659.4637 476.6587 724.2147 371.3567 638.2207 400.863259 575.5939 418.8237 642.2727 513.0907 593.5927 485.2837 659.4637 582.9477 546.3697 677.9457 563.5107 555.9207 412.2227 624.5917 437.7717 687.3127 419.8637"></polyline>
                    <polyline id="Stroke-10" stroke="url(#linearGradient-9)" stroke-linecap="round" stroke-linejoin="round" points="667.374 333.3719 609.254 384.5089 629.149 286.9159 446.433 324.7349 562.355 255.5739 499.412 224.7969 579.483 179.6659 583.652 102.8049 654.579 160.6159 579.483 179.6659 646.478 235.4059"></polyline>
                    <polyline id="Stroke-11" stroke="url(#linearGradient-10)" stroke-linecap="round" stroke-linejoin="round" points="403.7275 195.1229 320.1335 285.6339 222.7215 307.0749 248.9205 389.8629 332.34 495.8131 368.3596 440.9793 555.9205 412.2229 560.1275 387.4409 624.5915 437.7709 667.3735 333.3719 629.1495 286.9159 562.3555 255.5739 646.4775 235.4059 654.5795 160.6159 682.1255 139.3839"></polyline>
                    <polyline id="Stroke-12" stroke="url(#linearGradient-11)" stroke-linecap="round" stroke-linejoin="round" points="682.9511 184.0252 646.4771 235.4062 583.6521 102.8042 499.4121 224.7962 446.4321 324.7352 320.1341 285.6332"></polyline>
                    <polyline id="Stroke-13" stroke="url(#linearGradient-12)" stroke-linecap="round" stroke-linejoin="round" points="629.1494 286.9158 646.4774 235.4058 499.4124 224.7968 524.7044 152.9748 583.6524 102.8048 640.7324 0.6158"></polyline>
                    <polyline id="Stroke-15" stroke="url(#linearGradient-13)" stroke-linecap="round" stroke-linejoin="round" points="248.9208 389.8631 273.6154 445.7891 332.3393 495.8123 400.2787 575.5937"></polyline>
                    <polyline id="Stroke-16" stroke="url(#linearGradient-14)" stroke-linecap="round" stroke-linejoin="round" points="693.3027 743.7967 656.8947 792.7537 694.4717 807.0127"></polyline>
                    <polyline id="Stroke-18" stroke="url(#linearGradient-15)" stroke-linecap="round" stroke-linejoin="round" points="686.371 368.9598 667.374 333.3718 684.88 288.3398"></polyline>
                    <polyline id="Stroke-19" stroke="url(#linearGradient-16)" stroke-linecap="round" stroke-linejoin="round" points="371.3564 638.2205 321.5614 724.2345 476.6594 724.2145 418.8244 642.2725 371.3564 638.2205 324.7104 642.2545 124.7557 825.309888 248.9205 553.799357 400.862959 575.5937 447.7744 496.0025 461.6494 597.2955 400.862959 575.5937 324.7104 642.2545 248.9205 553.799357"></polyline>
                    <polyline id="Stroke-20" stroke="url(#linearGradient-17)" stroke-linecap="round" stroke-linejoin="round" points="483.052432 912.156015 467.220926 1018 341.0745 814.3955 467.220926 798.328774 483.052432 912.156015 341.0745 814.3955 476.6595 724.2145"></polyline>
                    <polyline id="Stroke-22" stroke="url(#linearGradient-18)" stroke-linecap="round" stroke-linejoin="round" points="524.704 152.9744 403.727 195.1224 499.412 224.7964 320.134 285.6334"></polyline>
                    <polyline id="Stroke-23" stroke="url(#linearGradient-19)" stroke-linecap="round" stroke-linejoin="round" points="321.5615 724.2342 124.7548 825.309588 341.0745 814.3952 321.5615 724.2342 324.7105 642.2552"></polyline>
                    <line x1="645.787" y1="878.9656" x2="511.538" y2="875.5356" id="Stroke-24" stroke="url(#linearGradient-20)" stroke-linecap="round" stroke-linejoin="round"></line>
                    <polyline id="Stroke-26" stroke="url(#linearGradient-21)" stroke-linecap="round" stroke-linejoin="round" points="273.6156 445.7889 171.863879 510.3275 124.7553 825.310288"></polyline>
                    <polygon id="Stroke-27" stroke="url(#linearGradient-22)" stroke-linecap="round" stroke-linejoin="round" points="76.274 254.451824 181.274 299.9364 145.476975 200.9364"></polygon>
                    <polygon id="Stroke-27-Copy" stroke="url(#linearGradient-23)" stroke-linecap="round" stroke-linejoin="round" transform="translate(281.954000, 928.085400) rotate(13.000000) translate(-281.954000, -928.085400) " points="222.454 931.573546 341.454 971.0854 300.884039 885.0854"></polygon>
                    <polygon id="Stroke-30" stroke="url(#linearGradient-24)" stroke-linecap="round" stroke-linejoin="round" points="30.9941 468.2088 64.3151 534.5608 83.2711 481.6238"></polygon>
                    <polygon id="Stroke-32" stroke="url(#linearGradient-25)" stroke-linecap="round" stroke-linejoin="round" points="71.4653 808.9002 99.6473 738.7682 20.2553 694.0472"></polygon>
                    <polygon id="Stroke-32-Copy" stroke="url(#linearGradient-26)" stroke-linecap="round" stroke-linejoin="round" transform="translate(24.755300, 347.047200) scale(-1, 1) translate(-24.755300, -347.047200) " points="30.7014155 377.0472 45.2553 340.409759 4.2553 317.0472"></polygon>
                    <g id="Group-164" transform="translate(0.000000, 301.581000)">
                        <path d="M249.166256,248.7465 C251.375426,248.7465 253.1665,250.537465 253.1665,252.746012 C253.1665,254.955535 251.375426,256.7465 249.166256,256.7465 C246.957574,256.7465 245.1665,254.955535 245.1665,252.746012 C245.1665,250.537465 246.957574,248.7465 249.166256,248.7465 Z" id="Stroke-38" fill="#B937DB"></path>
                        <path d="M222.7216,358.2567 C225.4356,358.2567 227.6356,360.4557 227.6356,363.1707 C227.6356,365.8847 225.4356,368.0847 222.7216,368.0847 C220.0076,368.0847 217.8076,365.8847 217.8076,363.1707 C217.8076,360.4557 220.0076,358.2567 222.7216,358.2567 Z" id="Stroke-46" fill="#B937DB" opacity="0.5"></path>
                        <path d="M434.524051,157.293834 C435.793965,156.229395 437.686201,156.396057 438.750263,157.665985 C439.814325,158.935547 439.647664,160.827803 438.377749,161.892242 C437.108201,162.956315 435.215965,162.789653 434.151537,161.519725 C433.087475,160.249796 433.254136,158.357907 434.524051,157.293834 Z" id="Stroke-54" fill="#B937DB" opacity="0.5"></path>
                        <path d="M471.1259,123.5643 C475.7469,119.6913 482.6329,120.2973 486.5059,124.9183 C490.3779,129.5403 489.7719,136.4253 485.1499,140.2983 C480.5289,144.1703 473.6439,143.5643 469.7719,138.9433 C465.8979,134.3223 466.5049,127.4363 471.1259,123.5643 Z" id="Stroke-56" fill="#B937DB"></path>
                        <path d="M474.9814,128.1649 C477.0624,126.4219 480.1614,126.6939 481.9044,128.7739 C483.6474,130.8539 483.3754,133.9539 481.2944,135.6969 C479.2144,137.4399 476.1154,137.1679 474.3724,135.0879 C472.6284,133.0079 472.9024,129.9079 474.9814,128.1649 Z" id="Stroke-58" fill="#B937DB"></path>
                        <path d="M488.2519,178.6532 C492.8729,174.7802 499.7589,175.3862 503.6309,180.0072 C507.5039,184.6292 506.8979,191.5142 502.2759,195.3872 C497.6549,199.2592 490.7699,198.6532 486.8969,194.0322 C483.0239,189.4112 483.6309,182.5252 488.2519,178.6532 Z" id="Stroke-60" fill="#B937DB"></path>
                        <path d="M492.1074,183.2538 C494.1874,181.5108 497.2874,181.7838 499.0304,183.8638 C500.7734,185.9438 500.5014,189.0428 498.4204,190.7858 C496.3404,192.5298 493.2414,192.2568 491.4984,190.1768 C489.7544,188.0968 490.0274,184.9968 492.1074,183.2538 Z" id="Stroke-62" fill="#B937DB"></path>
                        <path d="M436.469118,263.150053 C437.382221,261.139271 439.752871,260.249605 441.76414,261.162709 C443.77492,262.075813 444.664585,264.445978 443.751482,266.457249 C442.838379,268.468519 440.467729,269.358185 438.45646,268.445081 C436.44568,267.531977 435.556015,265.161324 436.469118,263.150053 Z" id="Stroke-66" fill="#B937DB" opacity="0.200000003"></path>
                        <path d="M476.7724,253.3778 C479.2654,247.8888 485.7364,245.4588 491.2264,247.9518 C496.7154,250.4448 499.1454,256.9168 496.6524,262.4058 C494.1594,267.8958 487.6884,270.3248 482.1984,267.8318 C476.7094,265.3388 474.2794,258.8678 476.7724,253.3778 Z" id="Stroke-68" fill="#B937DB"></path>
                        <path d="M482.2382,255.8602 C483.3602,253.3892 486.2732,252.2962 488.7442,253.4182 C491.2162,254.5402 492.3082,257.4532 491.1862,259.9232 C490.0642,262.3952 487.1512,263.4882 484.6802,262.3662 C482.2102,261.2442 481.1162,258.3312 482.2382,255.8602 Z" id="Stroke-70" fill="#B937DB"></path>
                        <path d="M516.0283,295.6522 C518.5213,290.1622 524.9933,287.7322 530.4823,290.2252 C535.9713,292.7182 538.4013,299.1902 535.9083,304.6792 C533.4163,310.1702 526.9443,312.5982 521.4543,310.1052 C515.9653,307.6122 513.5353,301.1412 516.0283,295.6522 Z" id="Stroke-72" fill="#B937DB"></path>
                        <path d="M521.4941,298.1337 C522.6161,295.6627 525.5291,294.5697 528.0001,295.6917 C530.4721,296.8137 531.5651,299.7267 530.4421,302.1977 C529.3201,304.6687 526.4081,305.7617 523.9361,304.6397 C521.4661,303.5177 520.3721,300.6057 521.4941,298.1337 Z" id="Stroke-74" fill="#B937DB"></path>
                        <path d="M331.818967,189.7264 C334.028138,189.7264 335.819212,191.517365 335.819212,193.725912 C335.819212,195.935435 334.028138,197.7264 331.818967,197.7264 C329.610286,197.7264 327.819212,195.935435 327.819212,193.725912 C327.819212,191.517365 329.610286,189.7264 331.818967,189.7264 Z" id="Stroke-78" fill="#B937DB"></path>
                        <path d="M222.7216,0.58 C225.4356,0.58 227.6356,2.78 227.6356,5.494 C227.6356,8.209 225.4356,10.408 222.7216,10.408 C220.0076,10.408 217.8076,8.209 217.8076,5.494 C217.8076,2.78 220.0076,0.58 222.7216,0.58 Z" id="Stroke-82" fill="#B937DB"></path>
                        <path d="M447.3974,18.1444 C450.15869,18.1444 452.3974,20.3831103 452.3974,23.1444 C452.3974,25.9056897 450.15869,28.1444 447.3974,28.1444 C444.63611,28.1444 442.3974,25.9056897 442.3974,23.1444 C442.3974,20.3831103 444.63611,18.1444 447.3974,18.1444 Z" id="Stroke-88" fill="#B937DB"></path>
                        <path d="M477.8261,64.8964 C481.4431,64.8964 484.3761,67.8294 484.3761,71.4464 C484.3761,75.0644 481.4431,77.9974 477.8261,77.9974 C474.2081,77.9974 471.2751,75.0644 471.2751,71.4464 C471.2751,67.8294 474.2081,64.8964 477.8261,64.8964 Z" id="Stroke-92" fill="#B937DB"></path>
                        <path d="M477.8261,68.4979 C479.4541,68.4979 480.7741,69.8179 480.7741,71.4459 C480.7741,73.0749 479.4541,74.3939 477.8261,74.3939 C476.1971,74.3939 474.8781,73.0749 474.8781,71.4459 C474.8781,69.8179 476.1971,68.4979 477.8261,68.4979 Z" id="Stroke-94" fill="#B937DB"></path>
                        <path d="M240.3124,339.5184 C241.9414,339.5184 243.2604,340.8374 243.2604,342.4664 C243.2604,344.0944 241.9414,345.4144 240.3124,345.4144 C238.6844,345.4144 237.3644,344.0944 237.3644,342.4664 C237.3644,340.8374 238.6844,339.5184 240.3124,339.5184 Z" id="Stroke-98" fill="#B937DB" opacity="0.200000003"></path>
                        <path d="M327.338918,288.1719 C330.10054,288.1719 332.3393,290.410831 332.3393,293.1719 C332.3393,295.932969 330.10054,298.1719 327.338918,298.1719 C324.57806,298.1719 322.3393,295.932969 322.3393,293.1719 C322.3393,290.410831 324.57806,288.1719 327.338918,288.1719 Z" id="Stroke-100" fill="#B937DB"></path>
                        <path d="M201.4218,237.9569 C203.0498,237.9569 204.3698,239.2769 204.3698,240.9059 C204.3698,242.5339 203.0498,243.8539 201.4218,243.8539 C199.7928,243.8539 198.4728,242.5339 198.4728,240.9059 C198.4728,239.2769 199.7928,237.9569 201.4218,237.9569 Z" id="Stroke-110" fill="#B937DB" opacity="0.200000003"></path>
                        <path d="M123.059161,327.0826 C124.163451,327.0826 125.0595,327.977444 125.0595,329.0826 C125.0595,330.187078 124.163451,331.0826 123.059161,331.0826 C121.954871,331.0826 121.0595,330.187078 121.0595,329.0826 C121.0595,327.977444 121.954871,327.0826 123.059161,327.0826 Z" id="Stroke-122" fill="#B937DB" opacity="0.699999988"></path>
                        <path d="M69.1454,331.9166 C71.3548656,331.9166 73.1454,333.707608 73.1454,335.916295 C73.1454,338.125592 71.3548656,339.9166 69.1454,339.9166 C66.936545,339.9166 65.1454,338.125592 65.1454,335.916295 C65.1454,333.707608 66.936545,331.9166 69.1454,331.9166 Z" id="Stroke-124" fill="#B937DB"></path>
                        <path d="M53.0969392,361.3273 C54.2012295,361.3273 55.0966,362.222671 55.0966,363.326961 C55.0966,364.431929 54.2012295,365.3273 53.0969392,365.3273 C51.9926488,365.3273 51.0966,364.431929 51.0966,363.326961 C51.0966,362.222671 51.9926488,361.3273 53.0969392,361.3273 Z" id="Stroke-130" fill="#B937DB" opacity="0.200000003"></path>
                        <path d="M391.772595,375.7457 C393.981892,375.7457 395.7729,377.536708 395.7729,379.745395 C395.7729,381.954692 393.981892,383.7457 391.772595,383.7457 C389.563908,383.7457 387.7729,381.954692 387.7729,379.745395 C387.7729,377.536708 389.563908,375.7457 391.772595,375.7457 Z" id="Stroke-132" fill="#B937DB" opacity="0.800000012"></path>
                        <path d="M369.272786,386.7457 C370.101272,386.7457 370.7729,387.417328 370.7729,388.245586 C370.7729,389.074072 370.101272,389.7457 369.272786,389.7457 C368.444528,389.7457 367.7729,389.074072 367.7729,388.245586 C367.7729,387.417328 368.444528,386.7457 369.272786,386.7457 Z" id="Stroke-132-Copy-2" fill="#B937DB" opacity="0.5"></path>
                        <path d="M340.772595,508.7457 C342.981892,508.7457 344.7729,510.536708 344.7729,512.745395 C344.7729,514.954692 342.981892,516.7457 340.772595,516.7457 C338.563908,516.7457 336.7729,514.954692 336.7729,512.745395 C336.7729,510.536708 338.563908,508.7457 340.772595,508.7457 Z" id="Stroke-132-Copy" fill="#B937DB"></path>
                        <path d="M447.584789,189.2313 C450.346084,189.2313 452.584789,191.470006 452.584789,194.2313 C452.584789,196.993205 450.346084,199.2313 447.584789,199.2313 C444.823495,199.2313 442.5854,196.993205 442.5854,194.2313 C442.5854,191.470006 444.823495,189.2313 447.584789,189.2313 Z" id="Stroke-142" fill="#B937DB"></path>
                        <path d="M400.278944,269.8202 C402.487626,269.8202 404.2787,271.611165 404.2787,273.819712 C404.2787,276.029235 402.487626,277.8202 400.278944,277.8202 C398.069774,277.8202 396.2787,276.029235 396.2787,273.819712 C396.2787,271.611165 398.069774,269.8202 400.278944,269.8202 Z" id="Stroke-146" fill="#B937DB"></path>
                        <path d="M419.634139,336.5008 C421.843309,336.5008 423.633895,338.291765 423.633895,340.5008 C423.633895,342.709835 421.843309,344.5008 419.634139,344.5008 C417.424969,344.5008 415.633895,342.709835 415.633895,340.5008 C415.633895,338.291765 417.424969,336.5008 419.634139,336.5008 Z" id="Stroke-150" fill="#B937DB"></path>
                        <path d="M63.7314,226.6893 C67.0449477,226.6893 69.7314,229.375752 69.7314,232.6893 C69.7314,236.002848 67.0449477,238.6893 63.7314,238.6893 C60.4178523,238.6893 57.7314,236.002848 57.7314,232.6893 C57.7314,229.375752 60.4178523,226.6893 63.7314,226.6893 Z" id="Stroke-152" fill="#B937DB"></path>
                        <path d="M146.19865,281.3378 C148.40815,281.3378 150.198834,283.128768 150.198834,285.3378 C150.198834,287.546832 148.40815,289.3378 146.19865,289.3378 C143.989884,289.3378 142.198834,287.546832 142.198834,285.3378 C142.198834,283.128768 143.989884,281.3378 146.19865,281.3378 Z" id="Stroke-156" fill="#B937DB" opacity="0.400000006"></path>
                        <path d="M97.6469,431.6366 C101.2649,431.6366 104.1969,434.5696 104.1969,438.1866 C104.1969,441.8046 101.2649,444.7376 97.6469,444.7376 C94.0299,444.7376 91.0969,441.8046 91.0969,438.1866 C91.0969,434.5696 94.0299,431.6366 97.6469,431.6366 Z" id="Stroke-160" fill="#B937DB"></path>
                        <path d="M126.6469,516.6366 C130.2649,516.6366 133.1969,519.5696 133.1969,523.1866 C133.1969,526.8046 130.2649,529.7376 126.6469,529.7376 C123.0299,529.7376 120.0969,526.8046 120.0969,523.1866 C120.0969,519.5696 123.0299,516.6366 126.6469,516.6366 Z" id="Stroke-160-Copy-2" fill="#B937DB"></path>
                        <path d="M4.6469,35.1871 C6.85636565,35.1871 8.6469,36.9781083 8.6469,39.1867947 C8.6469,41.3960917 6.85636565,43.1871 4.6469,43.1871 C2.43804504,43.1871 0.6469,41.3960917 0.6469,39.1867947 C0.6469,36.9781083 2.43804504,35.1871 4.6469,35.1871 Z" id="Stroke-160-Copy" fill="#B937DB" transform="translate(4.646900, 39.187100) scale(-1, 1) translate(-4.646900, -39.187100) "></path>
                        <path d="M59.6469,384.2381 C61.2749,384.2381 62.5959,385.5581 62.5959,387.1871 C62.5959,388.8151 61.2749,390.1351 59.6469,390.1351 C58.0189,390.1351 56.6989,388.8151 56.6989,387.1871 C56.6989,385.5581 58.0189,384.2381 59.6469,384.2381 Z" id="Stroke-162" fill="#B937DB"></path>
                        <polyline id="Path-15" stroke="url(#linearGradient-27)" stroke-linecap="round" stroke-linejoin="round" points="171.863879 208.7465 143.3564 120.538 90.6386 16.0175 222.6386 5.494 175.25491 90.7675228 273.6156 144.3983"></polyline>
                        <path d="M175.390329,87.6119 C177.046844,87.6119 178.3901,88.9551562 178.3901,90.611671 C178.3901,92.2686438 177.046844,93.6119 175.390329,93.6119 C173.733356,93.6119 172.3901,92.2686438 172.3901,90.611671 C172.3901,88.9551562 173.733356,87.6119 175.390329,87.6119 Z" id="Stroke-112" fill="#B937DB"></path>
                        <path d="M249.390329,84.6119 C251.046844,84.6119 252.3901,85.9551562 252.3901,87.611671 C252.3901,89.2686438 251.046844,90.6119 249.390329,90.6119 C247.733356,90.6119 246.3901,89.2686438 246.3901,87.611671 C246.3901,85.9551562 247.733356,84.6119 249.390329,84.6119 Z" id="Stroke-112-Copy" fill="#B937DB"></path>
                        <path d="M172.390329,205.6119 C174.046844,205.6119 175.3901,206.955156 175.3901,208.611671 C175.3901,210.268644 174.046844,211.6119 172.390329,211.6119 C170.733356,211.6119 169.3901,210.268644 169.3901,208.611671 C169.3901,206.955156 170.733356,205.6119 172.390329,205.6119 Z" id="Stroke-112" fill="#B937DB"></path>
                    </g>
                </g>
                <text id="Web-Development" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="304.5" y="369.5">Web </tspan>
                    <tspan x="304.5" y="388.5">Development</tspan>
                </text>
                <text id="3D-and-2D-Illustrati" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="323.5" y="467.5">3D and 2D </tspan>
                    <tspan x="323.5" y="486.5">Illustrations</tspan>
                </text>
                <text id="Animations" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="458.5" y="328.5">Animations</tspan>
                </text>
                <text id="Technical-Writing" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="335.5" y="602.5">Technical </tspan>
                    <tspan x="335.5" y="621.5">Writing</tspan>
                </text>
                <text id="Devops" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="463.5" y="424.5">Devops</tspan>
                </text>
                <text id="Mobile-Development-Copy" font-family="Montserrat-Medium, Montserrat" font-size="16" font-weight="400" letter-spacing="0.728" fill="#FFFFFF">
                    <tspan x="438.5" y="546.5">Mobile </tspan>
                    <tspan x="438.5" y="565.5">Development</tspan>
                </text>
                <path d="M445.9793,415.4678 C448.74059,415.4678 450.9793,417.70651 450.9793,420.4678 C450.9793,423.22909 448.74059,425.4678 445.9793,425.4678 C443.21801,425.4678 440.9793,423.22909 440.9793,420.4678 C440.9793,417.70651 443.21801,415.4678 445.9793,415.4678 Z" id="Stroke-40" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M317.8348,599.3614 C320.59609,599.3614 322.8348,601.599652 322.8348,604.360942 C322.8348,607.122232 320.59609,609.3614 317.8348,609.3614 C315.07351,609.3614 312.8348,607.122232 312.8348,604.360942 C312.8348,601.599652 315.07351,599.3614 317.8348,599.3614 Z" id="Stroke-44" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M422.119,546.6436 C424.88029,546.6436 427.119,548.88231 427.119,551.6436 C427.119,554.404432 424.88029,556.6436 422.119,556.6436 C419.35771,556.6436 417.119,554.404432 417.119,551.6436 C417.119,548.88231 419.35771,546.6436 422.119,546.6436 Z" id="Stroke-48" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M307.158,467.3614 C309.91929,467.3614 312.158,469.60011 312.158,472.3614 C312.158,475.12269 309.91929,477.3614 307.158,477.3614 C304.39671,477.3614 302.158,475.12269 302.158,472.3614 C302.158,469.60011 304.39671,467.3614 307.158,467.3614 Z" id="Stroke-80" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M286.6336,369.8663 C289.39489,369.8663 291.6336,372.10501 291.6336,374.8663 C291.6336,377.62759 289.39489,379.8663 286.6336,379.8663 C283.87231,379.8663 281.6336,377.62759 281.6336,374.8663 C281.6336,372.10501 283.87231,369.8663 286.6336,369.8663 Z" id="Stroke-84" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
                <path d="M440.7889,320.6329 C443.55019,320.6329 445.7889,322.87161 445.7889,325.6329 C445.7889,328.39419 443.55019,330.6329 440.7889,330.6329 C438.02761,330.6329 435.7889,328.39419 435.7889,325.6329 C435.7889,322.87161 438.02761,320.6329 440.7889,320.6329 Z" id="Stroke-136" stroke="#B937DB" stroke-width="3" fill="#FFFFFF"></path>
            </g>
        </g>
    </g>
                            </svg>
                        </div>
                   </div>

              </div>
        </div>
    </div>
</template>

<script>
    let played = false;
    function checkVisible(elm) {
        const rect = elm.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }
    const launchVideoIfVisible = (video1 = null, video2 = null) => {
        if (video1 && video2 && checkVisible(video1) && !played) {
            played = true;
            video1.play();
            video1.addEventListener('ended', () => {
                video1.currentTime = 0.1;
                setTimeout(() => {
                    video2.play();
                }, 1000)
            },false);
            video2.addEventListener('ended', () => {
                video2.currentTime = 0.1;
                setTimeout(() => {
                    video1.play();
                }, 2500)
            },false);
        }
    }
    export default {
        name: 'team',
        mounted() {
            const player1 = document.getElementById('team-video-1');
            const player2 = document.getElementById('team-video-2');
            launchVideoIfVisible(player1, player2);
            window.addEventListener('scroll', () => launchVideoIfVisible(player1, player2), false);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', launchVideoIfVisible, false);
        }
    }
</script>
